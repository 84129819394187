import React, { useEffect, useMemo, useState } from "react";
import ActivityItem from "./ActivityItem";
import { useSelector } from "react-redux";
import { filterIcon } from "../icons/Icons";
import Dropdown from "../../../../layouts/Dropdown";
import { ACTIVITY_TYPE } from "../../../../lib/constants";
import { useDispatch } from "react-redux";
import { fetchCandidateActivity } from "../../../../redux/services";
import { ScaleLoader } from "react-spinners";
import ResumeTab from "../Resume";
const core = require("../../../../lib/core");
import FeedbackIndividual from "../interview/FeedbackIndividual";
const ActivityTab = ({ checkResource = false }) => {
  const dispatch = useDispatch();
  const appId = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.applicant_id,
  );
  const match_profile_id = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile?._id,
  );

  const jobId = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile?.job,
  );
  const [currentTab, setCurrentTab] = useState("");
  const lst = useSelector((state) => state?.jobCandidateMatch?.activity);
  const [filter, setFilter] = useState("all");

  const filterLst = useMemo(() => {
    if (filter === "all") return lst;
    return lst.filter((act) => act.activity_type === filter);
  }, [lst, filter]);

  useEffect(() => {
    appId && getActivityData();
  }, [appId]);

  const getActivityData = async () => {
    try {
      dispatch(fetchCandidateActivity({ appId: appId }));
    } catch (error) {
      console.error(error);
    }
  };

  const filterActivity = (type) => () => {
    setFilter(type);
  };

  const handleSideButtonClick = (tabName) => {
    if (currentTab === tabName) {
      setCurrentTab("");
    } else {
      setCurrentTab(tabName);
    }
  };

  const renderContent = () => {
    if (!appId) {
      return (
        <section
          id="activity-section"
          data-testid="activity-section"
          className="w-full flex items-center justify-center py-10"
        >
          <ScaleLoader height={20} color="#1369E9" loading={true} />
        </section>
      );
    }

    return (
      <div className="flex w-full">
        {/* Activity Section */}
        <section
          id="activity-section"
          data-testid="activity-section"
          className={`py-4 h-screen overflow-y-auto px-6 transition-all duration-300 ease-in-out pl-10 ${currentTab ? "w-[45%]" : "w-full"}`}
        >
          {lst?.length > 0 ? (
            <>
              <div className="w-full flex justify-end mb-4">
                <Dropdown
                  listAlign={"right"}
                  buttonLabel={
                    <span className="flex gap-2 items-center">
                      {filterIcon}
                      {filter != "all" && (
                        <span className="text-gray-800 text-sm font-semibold capitalize">
                          {filter}
                        </span>
                      )}
                    </span>
                  }
                  dropdownItems={[
                    { label: "All", action: filterActivity("all") },
                    {
                      label: "Activity",
                      action: filterActivity(ACTIVITY_TYPE.ACTIVITY),
                    },
                    {
                      label: "Email",
                      action: filterActivity(ACTIVITY_TYPE.EMAIL),
                    },
                    {
                      label: "Sequence",
                      action: filterActivity(ACTIVITY_TYPE.SEQUENCE),
                    },
                    {
                      label: "Note",
                      action: filterActivity(ACTIVITY_TYPE.NOTE),
                    },
                  ]}
                  btnProps={{
                    "data-testid": "filter-dropdown",
                    "data-tooltip-id": "tooltip",
                    "data-tooltip-content": "Filter by activity type",
                  }}
                />
              </div>
              <div className="w-full border-l-2 border-gray-600">
                {filterLst?.map((act, idx) => (
                  <ActivityItem
                    data={act}
                    key={"activity" + idx}
                    data-testid={"activityItem"}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="w-full flex items-center justify-center">
              <ScaleLoader height={20} color="#1369E9" loading={true} />
            </div>
          )}
        </section>

        {currentTab === "Resume" && (
          <div className="w-[55%] bg-white rounded-md">
            <ResumeTab />
          </div>
        )}
        {currentTab === "Feedback" && (
          <div className="w-[55%] bg-white rounded-md">
            <FeedbackIndividual
              appId={match_profile_id}
              jobId={jobId}
              checkResource={checkResource}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full p-4 mb-4 flex flex-col md:flex-row">
      <div className="relative w-0 h-0 flex justify-end">
        <div className="fixed top-1/6 -translate-y-1/2 z-[400] flex flex-col gap-4 mr-6">
          <button
            onClick={() => handleSideButtonClick("Resume")}
            type="button"
            className={`rounded-full p-4 bg-white hover:text-blue group focus:outline-none focus:ring-2 focus:ring-white shadow-lg ${currentTab !== "Resume" ? "text-gray-800" : "text-blue"} `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M13 9h5.5L13 3.5zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m8 18v-1c0-1.33-2.67-2-4-2s-4 .67-4 2v1zm-4-8a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"
              />
            </svg>
          </button>
          <button
            onClick={() => handleSideButtonClick("Feedback")}
            type="button"
            className={`rounded-full p-4 bg-white ${currentTab !== "Feedback" ? "text-gray-800" : "text-blue"} hover:text-blue group focus:outline-none focus:ring-2 focus:ring-white shadow-lg`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M9 22c-.6 0-1-.4-1-1v-3H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2h-6.1l-3.7 3.7c-.2.2-.4.3-.7.3zm1-6v3.1l3.1-3.1H20V4H4v12zm6.3-10l-1.4 3H17v4h-4V8.8L14.3 6zm-6 0L8.9 9H11v4H7V8.8L8.3 6z"
              />
            </svg>
          </button>
        </div>
      </div>

      {renderContent()}
    </div>
  );
};

export default ActivityTab;
