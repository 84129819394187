import { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { CONTENT } from "../../../lib/constants";
import Modal from "../../../components/modal/Modal";
import { X } from "lucide-react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";
import { useStatusModal } from "../../../App";
const core = require("../../../lib/core");
import debounce from "lodash/debounce";

const ApplyForJobModal = ({ id, candidate, modalDismiss, jobId }) => {
  const jobList = useSelector((state) => state?.JobExpandSlice?.jobPositions);
  const ref = useRef();
  const [showDropdown, setShowDropDown] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobSelected, setJobSelected] = useState([]);
  const [message, setMessage] = useState({});
  const [applied, setApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccess, showError } = useStatusModal();

  useEffect(() => {
    const getJobList = async () => {
      setLoading(true);
      try {
        const response = await core.API(
          core.API_METHODS.GET,
          core.JOB_API.GET_ALL_JOBS_POSITIONS,
          1,
        );
        const jobs = response.data;
        const filtered = jobs.filter(
          (job) => job._id !== jobId && !job.isApplied,
        );
        setAllJobs(filtered);
        setFilteredJobs(filtered);
        setShowDropDown(true);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    getJobList();
  }, [jobId]);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (ref.current) {
        if (!ref.current.contains(e.target)) setShowDropDown(false);
        else setShowDropDown(true);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleSearch = (e) => {
    const term = e.target?.value?.toLowerCase() || "";
    debouncedSearch(term);
  };

  const debouncedSearch = useCallback(
    debounce((term) => {
      const filtered = allJobs.filter((job) =>
        job.name?.toLowerCase().includes(term),
      );
      setFilteredJobs(filtered);
    }, 300),
    [allJobs],
  );

  // const selectJob = (job) => {
  //   if (jobSelected.includes(job)) return;
  //   setJobSelected((prev) => [...prev, job]);
  //   ref.current.value = "";
  //   setFilteredJobs(allJobs.filter((job) => !jobSelected.includes(job)));
  // };

  const selectJob = (job) => {
    if (jobSelected.includes(job)) return;
    setJobSelected((prev) => [...prev, job]);
    ref.current.value = "";
    setFilteredJobs(allJobs.filter((job) => !jobSelected.includes(job)));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let data;

      if (jobSelected.length === 1) {
        data = {
          jobId: jobSelected[0]._id,
          candidate_details_id: candidate,
        };
      } else {
        data = jobSelected.map((job) => ({
          jobId: job._id,
          candidate_details_id: candidate,
        }));
      }

      const response = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_ATTACH_CANDIDATE_JOB,
        1,
        {
          jobIds: jobSelected?.map((job) => job._id),
          candidate_details_ids: Array.isArray(candidate)
            ? candidate
            : [candidate], // Ensure it's always an array
        },
      );

      setIsLoading(false);
      modalDismiss(); // Close the current modal
      showSuccess(
        "Candidate Attached Successfully",
        "The resume is being processed to evaluate the match. This may take a few moments. Once the matching is complete the profile will show under the candidate tab.",
      );
      setApplied(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      modalDismiss(); // Close the current modal
      showError(
        "Attachment Error",
        "An error occurred while attaching the candidate. Please try again.",
      );
    }
  };

  const removeJob = (job) => {
    setJobSelected((prev) => prev.filter((j) => j._id !== job._id));
    setFilteredJobs((prev) => [...prev, job]);
  };

  const handleInputClick = () => {
    setShowDropDown(true); // Ensure dropdown stays open on input click
  };

  return (
    <Modal
      modalDismiss={modalDismiss}
      heading={CONTENT.MODAL_CONTENT.ATTACH_CANDIDATE_TO_JOB.HEADING}
      subheading={CONTENT.MODAL_CONTENT.ATTACH_CANDIDATE_TO_JOB.SUBHEADING}
      onSave={handleSubmit}
      dismissOnSave={false}
      noBtns={applied}
      isLoading={isLoading}
    >
      {!applied && (
        <>
          <div className="w-full">
            <input
              className="w-full h-10 px-4 border-gray-500 border-2 rounded-lg"
              ref={ref}
              type="text"
              onChange={handleSearch}
              onClick={handleInputClick}
            />
            {showDropdown && (
              <div className="bg-white shadow w-full top-[50%] left-40 py-1 rounded-lg max-h-[360px] z-[9990] overflow-auto list-none cursor-pointer">
                {filteredJobs.map((job) => (
                  <li
                    key={job._id}
                    className="text-sm py-2 px-3 hover:bg-blue-100 hover:text-blue-800"
                    onClick={() => selectJob(job)}
                  >
                    {job.name}
                  </li>
                ))}
              </div>
            )}
          </div>
          <div className="w-full my-3 flex flex-wrap gap-2">
            {loading
              ? [...Array(8)].map((_, index) => (
                  <span
                    key={index}
                    // className="animate-pulse data-row  w-full px-2 py-1 rounded-lg shadow border border-gray-300 bg-blue-100 flex items-center justify-between"
                  >
                    {/* <ScaleLoader
                      width="100%"
                      height="18px"
                      className="animate-pulse"
                    /> */}
                  </span>
                ))
              : jobSelected.map((job) => (
                  <span
                    key={job._id}
                    className=" px-2 py-1 rounded-lg shadow border border-gray-300 bg-blue-100 flex items-center justify-between"
                  >
                    {job.name}
                    <X
                      className="h-4 w-4 ml-2 cursor-pointer"
                      onClick={() => removeJob(job)}
                    />
                  </span>
                ))}
          </div>
        </>
      )}
    </Modal>
  );
};

export default ApplyForJobModal;
