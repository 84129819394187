import React, { useEffect, useState } from "react";
import {
  fetchApplicantDetails,
  fetchCandidateActivity,
} from "../../../redux/services";
import { useDispatch } from "react-redux";
import TopBanner from "./TopBanner";
import ProfileSection from "./Profile";
import { ScaleLoader } from "react-spinners";
import { CandidatePageContext } from "../../../lib/context";
import ScheduleInterview from "../applicant/ScheduleInterview";
import InviteModal from "../job/assessment/InviteModal";
import ActivityTab from "./activity/ActivityTab";
import SchedulingInterview from "./interview/SchedulingInterview";
import NotesModal from "./notes/NotesModal";
import Modal from "../../modal/Modal";
import { useSelector } from "react-redux";
import FeedbackIndividual from "./interview/FeedbackIndividual";
import { clearActivity } from "../../../redux/slices/JobMatchCandidatesSlice";
const core = require("../../../lib/core");
const ResumeTab = React.lazy(() => import("./Resume"));
const InterviewTab = React.lazy(() => import("./interview/InterviewTestTab"));

const CandidateDrawer = ({
  source,
  isCandidateSection,
  candId,
  showNavbutton = true,
  updateLocalStatus,
  showActivities = true,
  showActions = true,
  setCandidates,
  setContentReload,
  setShowCandidate,
  setShowReviewPage,
  status,
  setShowReviewProspects,
  checkResource = false,
  showEditButton = true,
}) => {
  const dispatch = useDispatch();

  const appId = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.applicant_id,
  );
  const profile = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile,
  );
  const jobId = useSelector((state) => state?.JobExpandSlice?.profile?.id);

  const [activeTab, setActiveTab] = useState(<ProfileSection />);
  const [reload, setReload] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [message, setMessage] = useState({});
  // const candId = params?.candidateId;
  useEffect(() => {
    getApplicantsDetails(candId);
  }, [dispatch, reload, candId]);

  const getApplicantsDetails = async (id) => {
    await dispatch(fetchApplicantDetails({ candId: id }));
    // appId && dispatch(fetchCandidateActivity({ appId: appId }));
  };
  const btnList = [
    {
      name: "Details",
      component: <ProfileSection checkResource={checkResource} />,
      code: "Candidate_read",
    },
    // {
    //   name: "Applicant",
    //   component: <ResumeTab />,
    // },
    {
      name: "Activity",
      component: <ActivityTab checkResource={checkResource} />,
      code: "Candidate_read",
    },
    // Conditionally include "Schedule Interview", "Test & Interview", and "Feedback"
    ...(showActivities
      ? [
          {
            name: "Schedule Interview",
            component: <SchedulingInterview checkResource={checkResource} />,
            code: "Interview_create",
          },
          {
            name: "Reports",
            component: (
              <InterviewTab reload={reload} checkResource={checkResource} />
            ),
            code: "Interview_read",
          },
          {
            name: "Feedback",
            component: (
              <FeedbackIndividual
                appId={candId}
                jobId={jobId}
                checkResource={checkResource}
                showSidebutton={true}
              />
            ),
            code: "Candidate_interview_feedback_read",
          },
        ]
      : []),
    // Uncomment and include other tabs as needed
    // {
    //   name: "Analytics",
    //   component: <CandidateAnalytics />,
    // },
  ];

  const deleteNotes = async (id) => {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.DELETE,
        core.CANDIDATE_API.PUT_CANDIDATE_NOTES + id,
        1,
      );
      setLoading(false);
      if (json?.message == "OK") {
        dispatch(fetchCandidateActivity({ appId: appId }));
        setMessage({ type: "success", message: "Note deleted successfully" });
        setTimeout(() => {
          setMessage({});
          setShowModal(false);
        }, 1000);
      } else {
        setLoading(false);
        setMessage({
          type: "error",
          message: json?.message,
        });
        setTimeout(() => {
          setMessage({});
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setMessage({
        type: "error",
        message: "Something went wrong. Please try again",
      });
      setTimeout(() => {
        setMessage({});
      }, 1000);
    }
  };

  const [pipeline, setPipeline] = useState([]);
  useEffect(() => {
    if (profile) {
      if (profile?.pipeline?.length > 0) {
        setPipeline([
          ...profile?.pipeline?.flatMap((item) => {
            if (item?.subStage?.length > 0) {
              return item?.subStage?.map((sub) => `${sub}`);
            } else {
              return item?.stage;
            }
          }),
        ]);
      } else {
        setPipeline([
          "Application Review",
          "Screening",
          "Interview",
          "Hired",
          "To Be Declined",
          "Rejected",
        ]);
      }
    }
  }, [profile]);

  const reviewMode = () => {
    setShowCandidate(false);

    if (status && status == "Prospect") {
      setShowReviewProspects(true);
    } else {
      setShowReviewPage(true);
    }
  };
  return (
    <CandidatePageContext.Provider value={{ setReload, reload, setShowModal }}>
      <section
        id="candidateDrawer"
        className="relative block z-[200] w-screen"
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
        <div className="pointer-events-none fixed inset-y-0 right-0 flex w-[94%] max-w-full pl-10">
          <div className="pointer-events-auto relative w-full">
            <div className="absolute left-0 top-0 -ml-8 flex flex-col gap-4 pr-2 pt-4 sm:-ml-16 sm:pr-4">
              <button
                onClick={() => {
                  setShowCandidate(false);
                  dispatch(clearActivity());
                }}
                type="button"
                className="relative rounded-full p-4 bg-white text-gray-800 group focus:outline-none focus:ring-2 focus:ring-white"
              >
                <svg
                  className="h-6 w-6 group-hover:stroke-blue"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            {pageLoading ? (
              <div className="flex h-full flex-col bg-white shadow-xl items-center justify-center">
                <ScaleLoader height={30} color="#1369E9" loading={true} />
              </div>
            ) : (
              <div className="flex h-full flex-col overflow-y-auto bg-white pt-6 shadow-xl">
                <TopBanner
                  updateLocalStatus={updateLocalStatus}
                  isCandidateSection={isCandidateSection}
                  checkResource={checkResource}
                  candId={candId}
                  setCandidates={setCandidates}
                  setActiveTab={setActiveTab}
                  btnList={btnList}
                  reviewMode={reviewMode}
                  reload={setReload}
                  pipeline={pipeline}
                  showActivities={showActivities}
                  showActions={showActions}
                  showEditButton={showEditButton}
                  setContentReload={setContentReload}
                />
                <React.Suspense
                  fallback={
                    <div className="w-full h-full flex grow item-center justify-center">
                      <ScaleLoader color="#2563EB" loading={true} />
                    </div>
                  }
                >
                  {activeTab}
                </React.Suspense>
              </div>
            )}
          </div>
        </div>
        {showModal && showModal?.type == "interview" ? (
          <ScheduleInterview
            CandidateId={candId}
            edit={showModal?.edit}
            interviewDetails={showModal?.edit ? showModal?.data : {}}
          />
        ) : showModal?.type == "assessment" ? (
          <InviteModal
            modalDismiss={closeModal}
            assessmentDetails={activeAssessments}
          />
        ) : showModal?.type == "notes" ? (
          <NotesModal
            edit={showModal?.edit ?? false}
            data={showModal?.data ?? ""}
            noteid={showModal?.id ?? ""}
          />
        ) : showModal?.type == "deleteNotes" ? (
          <Modal
            heading={"Delete this note"}
            subheading={"Are you sure you want to delete this note?"}
            modalDismiss={() => setShowModal(false)}
            onSave={() => deleteNotes(showModal?.id)}
            defaultButtons={{
              primaryClass: "hover:!bg-[#ad3333] !bg-red-900 !border-[#ad3333]",
              primaryLabel: "Delete",

              primaryDisabled: loading,
            }}
            isLoading={loading}
          >
            {Object.entries(message)?.length > 0 && (
              <span
                className={
                  message.type == "success"
                    ? "text-green-800 font-normal text-sm"
                    : "text-red-800 font-normal text-sm"
                }
              >
                {message.message}
              </span>
            )}
          </Modal>
        ) : null}
      </section>
    </CandidatePageContext.Provider>
  );
};

export default CandidateDrawer;
