import React, { useContext, useState } from "react";
import Modal from "../../../modal/Modal";
import { CandidatePageContext } from "../../../../lib/context";
import { ScaleLoader } from "react-spinners";
import { useSelector, useDispatch } from "react-redux";
import { fetchCandidateActivity } from "../../../../redux/services";
import { useStatusModal } from "../../../../App";
const core = require("../../../../lib/core");

const NotesModal = ({ edit = false, data, noteid }) => {
  const dispatch = useDispatch();
  const { setShowModal } = useContext(CandidatePageContext);
  const { showSuccess, showError } = useStatusModal(); // Access the functions
  const { id, appId } = useSelector((state) => {
    return {
      id: state?.jobCandidateMatch?.profile?.matchProfile?._id,
      appId: state?.jobCandidateMatch?.profile?.applicant_id,
    };
  });

  const [content, setContent] = useState(edit ? data : "");
  const [loading, setLoading] = useState(false);

  const createNotes = async () => {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_CANDIDATE_NOTES,
        1,
        {
          note: content,
          matchprofile: id,
        },
      );
      console.log(json);
      if (json?.data) {
        setLoading(false);
        setShowModal(false); // Close the current modal
        showSuccess(
          "Note Added Successfully",
          "Your note has been added successfully to the candidate's profile.",
        );
        dispatch(fetchCandidateActivity({ appId: appId }));
      } else {
        console.log(json);
        setLoading(false);
        setShowModal(false); // Close the current modal
        showError(
          "Note Addition Error",
          "Something went wrong while adding the note. Please try again.",
        );
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setShowModal(false); // Close the current modal
      showError(
        "Note Addition Error",
        "Something went wrong while adding the note. Please try again.",
      );
    }
  };

  const updateNotes = async () => {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.PUT,
        core.CANDIDATE_API.PUT_CANDIDATE_NOTES + noteid,
        1,
        {
          note: content,
          matchprofile: id,
        },
      );
      setLoading(false);
      if (json?.message == "OK") {
        setShowModal(false); // Close the current modal
        showSuccess(
          "Note Updated Successfully",
          "Your note has been updated successfully on the candidate's profile.",
        );
        dispatch(fetchCandidateActivity({ appId: appId }));
      } else {
        setShowModal(false); // Close the current modal
        showError(
          "Note Update Error",
          "Something went wrong while updating the note. Please try again.",
        );
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setShowModal(false); // Close the current modal
      showError(
        "Note Update Error",
        "Something went wrong while updating the note. Please try again.",
      );
    }
  };

  return (
    <Modal
      heading={edit ? "Edit Note" : "Add New Note"}
      subheading={"Enter your notes referring to the candidate"}
      modalDismiss={() => setShowModal(false)}
      onSave={edit ? updateNotes : createNotes}
      defaultButtons={{
        primaryLabel: "Save",
        primaryDisabled: loading,
      }}
      isLoading={loading}
    >
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        className="w-full h-40 p-4 border border-gray-300 rounded-md"
        placeholder="Enter your notes here"
      ></textarea>
    </Modal>
  );
};

export default NotesModal;
