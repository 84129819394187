import React from "react";
import Modal from "../../../modal/Modal";
import { ScaleLoader } from "react-spinners";
const ConfirmationModal = ({
  ModalHeading,
  modalDismiss,
  Cancelevent,
  CancelLoading,
}) => {
  const modalElements = {
    heading: ModalHeading,
    // subheading: "Send availability invite to candidates.",
    modalDismiss: modalDismiss,
    onSave: Cancelevent,
    defaultButtons: {
      secLabel: "Back",
      primaryLabel: CancelLoading ? (
        <ScaleLoader height={14} color="#ffffff" loading={true} />
      ) : (
        "Cancel"
      ),
    },
  };
  return (
    <Modal {...modalElements}>
      <div>
        <p className="text-base py-2">
          Are you sure you want to cancel this event?
        </p>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
