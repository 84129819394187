import { ClipLoader, ScaleLoader } from "react-spinners";
export default function Modal({
  id,
  heading,
  subheading,
  modalDismiss,
  children,
  onSave,
  dismissOnSave = false,
  customButtons,
  defaultButtons,
  className,
  onCancel,
  noBtns = false,
  style = {},
  isLoading = false,
}) {
  return (
    <>
      <div
        className="fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity z-[900]"
        onClick={(e) => modalDismiss(e)}
      />
      <div
        className={`pointer-events-none fixed inset-0 flex justify-center items-center w-screen z-[999]`}
        id={id ?? "custom-modal"}
      >
        <div
          className={`text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg max-w-[66%] min-w-[280px] max-h-[90vh] bg-white rounded-lg pointer-events-auto relative w-full flex flex-col ${className}`}
          style={{ ...style }}
        >
          <div className="sticky top-0 bg-white px-5 pt-5  rounded-t-lg">
            <button
              onClick={(e) => modalDismiss(e)}
              type="button"
              className="cursor-pointer absolute right-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M24 24L8 8M24 8L8 24"
                  stroke="#001B4B"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <div className="w-full flex flex-col gap-5 mb-4">
              {heading && String(heading)?.length > 0 && (
                <h1 className="text-black text-base font-bold w-[calc(100%-32px)] not-italic">
                  {heading}
                </h1>
              )}
              {subheading && String(subheading)?.length > 0 && (
                <h6 className="text-gray-700 text-sm -mt-3 not-italic font-normal">
                  {subheading}
                </h6>
              )}
            </div>
          </div>
          <div className="max-w-full overflow-y-auto flex-1 px-5 py-2">
            {children}
          </div>
          {(customButtons?.length > 0 || !noBtns) && (
            <div className="sticky bottom-0 bg-white px-5 pb-5  rounded-b-lg">
              {customButtons?.length > 0 ? (
                <div className="w-full flex items-end gap-4 flex-row-reverse self-end mt-2">
                  {customButtons?.map((btn, idx) => (
                    <button
                      key={idx}
                      className={`btn-md ${btn?.className}`}
                      type={btn?.type ?? "button"}
                      onClick={btn.function}
                    >
                      {btn?.label}
                    </button>
                  ))}
                </div>
              ) : (
                !noBtns && (
                  <div className="w-full flex items-end gap-4 flex-row-reverse self-end mt-2">
                    <button
                      disabled={
                        defaultButtons?.primaryDisabled ?? isLoading ?? false
                      }
                      type="button"
                      className={
                        "btn-md btn-primary disabled:opacity-60 disabled:pointer-events-none !bg-blue flex items-center justify-center gap-2 " +
                        defaultButtons?.primaryClass
                      }
                      onClick={() => {
                        onSave();
                        if (dismissOnSave) {
                          setTimeout(() => modalDismiss(), 1500);
                        }
                      }}
                    >
                      {isLoading ? (
                        <>
                          <ScaleLoader
                            height={14}
                            color="#ffffff"
                            loading={true}
                          />
                        </>
                      ) : (
                        defaultButtons?.primaryLabel ?? "Save"
                      )}
                    </button>
                    <button
                      className={"btn-md btn-sec " + defaultButtons?.secClass}
                      onClick={onCancel ? onCancel : modalDismiss}
                      type="button"
                    >
                      {defaultButtons?.secLabel ?? "Cancel"}
                    </button>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
