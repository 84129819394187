import React, { useState, useRef } from "react";

const core = require("../../../../../lib/core");

function UploadFiles({ activeTab, uploadedFiles, setUploadedFiles }) {
  //   const [activeTab, setActiveTab] = useState("files");
  const fileInputRef = useRef(null);
  const folderInputRef = useRef(null);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFolderChange = (event) => {
    const folderFiles = Array.from(event.target.files);

    setUploadedFiles((prevFiles) => [...prevFiles, ...folderFiles]);
  };

  console.log(uploadedFiles);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const droppedFiles = Array.from(event.dataTransfer.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const removeFile = (fileToRemove) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove),
    );
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  return (
    <div className="w-full  mx-auto bg-white  rounded-lg overflow-hidden">
      {/* Tabs */}

      {/* Upload Area */}
      <div className="mb-4 ">
        {activeTab === "files" && (
          <div
            className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center flex flex-col items-center justify-center cursor-pointer hover:bg-gray-100 hover:border-[3px] transition-all duration-100"
            onDragOver={handleDragOver}
            onClick={() => fileInputRef.current.click()}
            onDrop={handleDrop}
          >
            <input
              type="file"
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden"
            />
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 text-muted-foreground"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                />
              </svg>
            </p>
            <p className="mt-4 mb-2 text-sm font-medium">
              Drag and drop files here{" "}
            </p>
            <p className="text-xs text-muted-foreground mb-4">
              {" "}
              or click to browse files from your computer
            </p>
          </div>
        )}

        {activeTab === "folder" && (
          <div
            className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center flex flex-col items-center justify-center cursor-pointer hover:bg-gray-100 hover:border-[3px] transition-all duration-100"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => folderInputRef.current.click()}
          >
            <input
              type="file"
              multiple
              directory=""
              webkitdirectory=""
              ref={folderInputRef}
              onChange={handleFolderChange}
              className="hidden"
            />
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 text-muted-foreground"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                />
              </svg>
            </p>
            <p className="mt-4 mb-2 text-sm font-medium">
              Drag and drop folder here{" "}
            </p>
            <p className="text-xs text-muted-foreground mb-4">
              {" "}
              or click to browse files from your computer
            </p>
          </div>
        )}
      </div>

      {/* Uploaded Files List */}
      {uploadedFiles.length > 0 && (
        <div className="p-4 bg-gray-50 max-h-72 overflow-y-auto">
          <h3 className="text-lg font-semibold mb-3 text-gray-700">
            Uploaded Files ({uploadedFiles.length})
          </h3>
          {uploadedFiles.map((file, index) => (
            <div
              key={index}
              className="flex items-center justify-between bg-white p-3 rounded-lg mb-2 shadow-sm"
            >
              <div className="flex-1 mr-4 overflow-hidden">
                <p className="text-sm font-medium text-gray-800 truncate">
                  {file.name || file.webkitRelativePath}
                </p>
                <p className="text-xs text-gray-500">
                  {formatFileSize(file.size)}
                </p>
              </div>
              <button
                onClick={() => removeFile(file)}
                className="text-red-500 hover:bg-red-100 p-2 rounded-full transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default UploadFiles;
