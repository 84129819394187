import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCalendar, FaRegClock, FaChevronDown } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";

const EmailScheduler = ({
  scheduleOption,
  setScheduleOption,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  scheduleOptions,
}) => {
  const handleOptionChange = (e) => {
    setScheduleOption(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(null); // to reset the time when the date changes
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const filterTime = (time) => {
    const currentDate = new Date();
    const selectedDateTime = new Date(selectedDate);

    // Set the time of the selected date to the time being checked
    selectedDateTime.setHours(time.getHours(), time.getMinutes(), 0, 0);

    // If it's today, don't allow past times
    if (
      selectedDate &&
      selectedDate.getDate() === currentDate.getDate() &&
      selectedDate.getMonth() === currentDate.getMonth() &&
      selectedDate.getFullYear() === currentDate.getFullYear()
    ) {
      return currentDate.getTime() < selectedDateTime.getTime();
    }

    // For future dates, allow all times
    return true;
  };

  return (
    <div className="mt-4 space-y-4">
      <div className="flex items-center">
        <div className="relative w-48">
          <label
            className="text-right text-[14px] font-normal not-italic required"
            htmlFor=""
          >
            Schedule email
          </label>
          <select
            value={scheduleOption}
            onChange={handleOptionChange}
            className="w-full p-2 pr-8 border border-gray-400  rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC]"
          >
            {scheduleOptions?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="absolute top-[35px] right-0 flex items-center px-2 pointer-events-none">
            <IoMdArrowDropdown size={20} className="text-gray-500" />
          </div>
        </div>
      </div>
      {scheduleOption === "custom" && (
        <div className="flex space-x-2">
          <div className="relative">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              placeholderText="mm/dd/yyyy"
              minDate={new Date()} // Prevent selecting past dates
              className="w-full p-2 pl-10 border border-gray-400  rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC]"
            />
            <FaRegCalendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700" />
          </div>
          <div className="relative">
            <DatePicker
              selected={selectedTime}
              onChange={handleTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              filterTime={filterTime}
              placeholderText="--:-- --"
              className="w-full p-2 pl-10 border border-gray-400  rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC]"
            />
            <FaRegClock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700" />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailScheduler;
