import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { reparseJobService } from "../../../redux/services";
import { ScaleLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { JobPageContext } from "../../../lib/context";
import Modal from "../../modal/Modal";
import ShareJobModal from "./ShareJobModal";
import QuestionGenerateModal from "./QuestionGenerateModal";
import { CONTENT } from "../../../lib/constants";
import QuestionEvaluationModal from "./QuestionEvaluationModal";
import CloneJobModal from "./CloneJobModal";
import InterviewEvaluationModal from "./InterviewEvaluationModal";
const core = require("../../../lib/core");

function JobStatusButton({ params, ...props }) {
  const nav = useNavigate();
  const { setReload } = useContext(JobPageContext);
  // Toggle menu on the job top banner
  const menuRef = useRef(null);
  const { job, jobData, jobId } = useSelector((state) => {
    return {
      job: state?.JobExpandSlice?.description,
      jobData: state?.JobExpandSlice?.profile,
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });
  const [showMenu, setShowMenu] = useState(false);
  // If any action is selected
  const [actionSelected, setActionSelected] = useState("");
  const [actionMsg, setActionMsg] = useState("");
  const jobActions =
    params.jobType == "private" || params.jobType == "draft"
      ? [
          { name: "Publish job", tag: "active" },
          { name: "Close job", tag: "closed" },
          { name: "Archive job", tag: "archived" },
          { name: "Delete job", tag: "deleted" },
        ]
      : params.jobType == "closed"
        ? [
            { name: "Publish job", tag: "active" },
            { name: "Unpublish job", tag: "draft" },
            { name: "Archive job", tag: "archived" },
            { name: "Delete job", tag: "deleted" },
          ]
        : params.jobType == "archived"
          ? [
              { name: "Publish job", tag: "active" },
              { name: "Close job", tag: "closed" },
              { name: "Unpublish job", tag: "draft" },
              { name: "Delete job", tag: "deleted" },
            ]
          : params.jobType == "unprocessed"
            ? [
                { name: "Publish job", tag: "active" },
                { name: "Close job", tag: "closed" },
                { name: "Unpublish job", tag: "draft" },
                { name: "Delete job", tag: "deleted" },
              ]
            : [
                { name: "Unpublish job", tag: "draft" },
                { name: "Close job", tag: "closed" },
                { name: "Archive job", tag: "archived" },
                { name: "Delete job", tag: "deleted" },
              ];

  const updateJobStatus = async (status) => {
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.JOB_API.PUT_UPDATE_JOB + jobData?.id,
        1,
        {
          status: status,
        },
      );
      if (json.data) {
        setActionMsg(
          status == "deleted"
            ? CONTENT.MODAL_CONTENT.DELETE_JOB.SUCCESS
            : status == "archived"
              ? CONTENT.MODAL_CONTENT.ARCHIVE_JOB.SUCCESS
              : status == "active"
                ? CONTENT.MODAL_CONTENT.PUBLISH_JOB.SUCCESS
                : CONTENT.MODAL_CONTENT.UNPUBLISH_JOB.SUCCESS,
        );
        setTimeout(() => {
          if (status == "deleted") {
            console.log("djdj");
            nav("/job/active", { replace: true });
            window.location.reload();

            console.log("called");
          } else {
            nav("/job/" + status + "/" + jobData?.id + "/description");
          }
        }, 3000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    // Add a click event listener to the document
    const handleDocumentClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        // Clicked outside of the menu, close the menu
        setShowMenu(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const dispatch = useDispatch();
  const [parse, setParse] = useState("Parse job");
  const reParse = async () => {
    dispatch(reparseJobService({ jobId: jobId }));
    setParse("Parsing...");
  };

  useEffect(() => {
    setParse("Parse job");
  }, [jobData?.id]);

  const [loader, setLoader] = useState(false);
  const generateQuestion = async () => {
    setLoader(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_QUESTIONS_GENERATE,
        // jobData?.id,
        1,
        {
          jobId: jobData?.id,
        },
      );
      if (json.data) {
        setLoader(false);
        setShowQuestionModal(true);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const [loadingRematch, setLoadingRematch] = useState(false);
  const reMatchCandidates = async () => {
    setLoadingRematch(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_RE_MATCH_CANDIDATES,
        // jobData?.id,
        1,
        {
          jobid: jobData?.id,
        },
      );
      if (json.data) {
        setLoadingRematch(false);
        setReload((prev) => prev + 1);
      }
    } catch (error) {
      setLoadingRematch(false);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showEvaluationModal, setShowEvaluationModal] = useState(false);
  const [showinterviewEvaluationModal, setShowinterviewEvaluationModal] =
    useState(false);
  const [showJobCloneModal, setShowJobCloneModal] = useState(false);
  const sourceCandidate = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_SOURCE_CANDIDATES + jobId,
        1,
      );
      if (json) toast.success("Sourcing candidates initiated successfully");
    } catch (error) {
      toast.error("Sourcing candidates failed. Please try again later.");
    }
  };

  return (
    <>
      {actionSelected && (
        <Modal
          heading={jobData?.position + " @ " + jobData?.company?.name}
          modalDismiss={() => setActionSelected("")}
          subheading={
            actionSelected == "deleted"
              ? CONTENT.MODAL_CONTENT.DELETE_JOB.SUBHEADING
              : actionSelected == "archived"
                ? CONTENT.MODAL_CONTENT.ARCHIVE_JOB.SUBHEADING
                : actionSelected == "draft"
                  ? CONTENT.MODAL_CONTENT.UNPUBLISH_JOB.SUBHEADING
                  : actionSelected == "active"
                    ? CONTENT.MODAL_CONTENT.PUBLISH_JOB.SUBHEADING
                    : null
          }
          dismissOnSave={false}
          onSave={() => {
            updateJobStatus(actionSelected);
          }}
          defaultButtons={{ primaryLabel: "Confirm" }}
          onReset={() => {
            setActionSelected("");
          }}
        >
          {actionMsg?.length > 0 && (
            <span className="block w-full text-sm font-normal text-bluegreen-800">
              {actionMsg}
            </span>
          )}
        </Modal>
      )}

      {showModal && (
        <ShareJobModal
          jobId={jobData?.id}
          modalDismiss={() => {
            setShowModal(false);
            // setContentReload((prev) => prev + 1);
          }}
          // jobId={jobDetails?.id}
          assessmentDetails={
            props?.assessments?.length > 0
              ? props?.assessments?.filter((d) => d?.status == "active")
              : null
          }
        />
      )}

      {showQuestionModal && (
        <QuestionGenerateModal
          jobId={jobData?.id}
          modalDismiss={() => {
            setShowQuestionModal(false);
            // setContentReload((prev) => prev + 1);
          }}
          // jobId={jobDetails?.id}
        />
      )}

      {showEvaluationModal && (
        <QuestionEvaluationModal
          jobId={jobData?.id}
          modalDismiss={() => {
            setShowEvaluationModal(false);
          }}
          // jobId={jobDetails?.id}
        />
      )}

      {showJobCloneModal && (
        <CloneJobModal
          jobId={jobData?.id}
          modalDismiss={() => {
            setShowJobCloneModal(false);
          }}
          // jobId={jobDetails?.id}
        />
      )}

      {showinterviewEvaluationModal && (
        <InterviewEvaluationModal
          jobId={jobData?.id}
          modalDismiss={() => {
            setShowinterviewEvaluationModal(false);
          }}
          // jobId={jobDetails?.id}
        />
      )}

      <div className="relative" ref={menuRef}>
        <button
          className="border-button inline-block min-w-[40px] w-[40px] h-[40px] text-center py-2 bg-white rounded-lg shadow border border-gray-300"
          type="button"
          onClick={() => setShowMenu(!showMenu)}
        >
          <svg
            className="mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.33398 4.79134C8.33398 3.98593 8.9869 3.33301 9.79232 3.33301C10.5977 3.33301 11.2507 3.98593 11.2507 4.79134C11.2507 5.59676 10.5977 6.24967 9.79232 6.24967C8.9869 6.24967 8.33398 5.59676 8.33398 4.79134ZM8.33398 9.79134C8.33398 8.98593 8.9869 8.33301 9.79232 8.33301C10.5977 8.33301 11.2507 8.98593 11.2507 9.79134C11.2507 10.5968 10.5977 11.2497 9.79232 11.2497C8.9869 11.2497 8.33398 10.5968 8.33398 9.79134ZM9.79232 13.333C8.9869 13.333 8.33398 13.9859 8.33398 14.7913C8.33398 15.5968 8.9869 16.2497 9.79232 16.2497C10.5977 16.2497 11.2507 15.5968 11.2507 14.7913C11.2507 13.9859 10.5977 13.333 9.79232 13.333Z"
              fill="#252E4B"
            />
          </svg>
        </button>
        {showMenu && (
          <div className="top-[45px]  absolute bg-[#FFF] ml-[-135px] no-list-style text-left rounded-[8px] p-[8px] w-[176px] shadow-menuOptionShadow z-10">
            <li
              className={`px-[8px] py-[7px] text-[#252E4B] text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px] `}
              onClick={generateQuestion}
              // onClick={()=>setShowQuestionModal(true)}
              disabled={loader}
            >
              {loader ? (
                <ScaleLoader height={20} color="#1369E9" loading={true} />
              ) : (
                <>Generate question</>
              )}
            </li>

            <li
              className={`px-[8px] py-[7px] text-[#252E4B] text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px] `}
              onClick={() => setShowEvaluationModal(true)}
              disabled={loader}
            >
              Questions
            </li>

            {/* <li
              className={`px-[8px] py-[7px] text-[#252E4B] text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px] `}
              onClick={() => setShowinterviewEvaluationModal(true)}
              disabled={loader}
            >
              Evaluation plan
            </li> */}

            <li
              className={`px-[8px] py-[7px] text-[#252E4B] text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px] `}
              onClick={() => reMatchCandidates()}
              disabled={loadingRematch}
            >
              {loadingRematch ? (
                <ScaleLoader height={20} color="#1369E9" loading={true} />
              ) : (
                <>Re match candidates</>
              )}
            </li>
            <li
              onClick={sourceCandidate}
              className={`px-[8px] py-[7px] text-[#252E4B] text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px] `}
            >
              Source candidates
            </li>
            <li
              className={`px-[8px] py-[7px] text-[#252E4B] text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px] `}
              onClick={() => setShowJobCloneModal(true)}
              disabled={loader}
            >
              Clone job
            </li>

            {params.jobType != "archived" && (
              <li
                className="px-[8px] py-[7px]  text-[#252E4B] text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px]"
                onClick={() => {
                  nav("/edit-job/" + jobData?.id, {
                    state: params.jobType,
                  });
                }}
              >
                Edit job
              </li>
            )}
            <li
              onClick={reParse}
              className={`px-[8px] py-[7px] text-[#252E4B] ${
                job?.parse === "In Progress" || parse === "Parsing..."
                  ? "pointer-events-none"
                  : ""
              }  text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px]`}
            >
              {job?.parse === "In Progress" ? "Parsing..." : parse}
            </li>

            {/* <button onClick={generateQuestion} disabled={loader} className="border-button w-auto text-center btn-md bg-white rounded-lg shadow border border-gray-300">
                {loader ? (
                  <ScaleLoader height={20} color="#1369E9" loading={true} />
                ) : (
                  <>
                    Question generate
                  </>
                )}
  
  
              </button> */}

            <li
              className={`px-[8px] py-[7px] text-[#252E4B] text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px] `}
              onClick={() => setShowModal(true)}
            >
              Share job
            </li>

            {jobActions.map((action, idx) => (
              <li
                className={`px-[8px] py-[7px] text-[#252E4B] text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px] ${
                  action?.name === "Delete job"
                    ? "text-[#FF475D] text-[14px] border-t border-t-[#EFF0F2]"
                    : ""
                }`}
                key={idx}
                data-toggle="modal"
                data-target="#confirmPopup"
                onClick={(e) => {
                  setActionSelected(action.tag);
                }}
              >
                {action.name}
              </li>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default JobStatusButton;
