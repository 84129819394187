import React, { useState } from "react";
import Modal from "../../../modal/Modal";
import { ScaleLoader } from "react-spinners";
import TextAreaBox from "../../../../layouts/TextAreaBox";
import { useStatusModal } from "../../../../App";
const core = require("../../../../lib/core");

function Notes({ modalDismiss, id }) {
  const { showSuccess, showError } = useStatusModal(); // Access the functions
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  const createNotes = async () => {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_CANDIDATE_NOTES,
        1,
        {
          note: content,
          matchprofile: id,
        },
      );
      if (json?.data) {
        setLoading(false);
        modalDismiss(); // Close the current modal
        showSuccess(
          "Note Added Successfully",
          "Your note has been added successfully to the candidate's profile.",
        );
      } else {
        setLoading(false);
        modalDismiss(); // Close the current modal
        showError(
          "Note Addition Error",
          "Something went wrong while adding the note. Please try again.",
        );
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      modalDismiss(); // Close the current modal
      showError(
        "Note Addition Error",
        "Something went wrong while adding the note. Please try again.",
      );
    }
  };

  return (
    <Modal
      heading={"Add new note"}
      subheading={"Enter your notes referring to the candidate"}
      modalDismiss={() => modalDismiss()}
      onSave={createNotes}
      defaultButtons={{
        primaryLabel: "Save",
        primaryDisabled: loading,
      }}
      isLoading={loading}
    >
      <TextAreaBox
        value={content}
        size="200px"
        onChange={(e) => setContent(e.target.value)}
        placeholder="Enter your notes here"
      />
    </Modal>
  );
}

export default Notes;
