const ImageComponent = ({ imgUrl, name }) => {
  const imageUrl =
    "https://res.cloudinary.com/dc6deairt/image/upload/v1638102932/user-32-01_pfck4u.jpg";
  const altText = "Marie Zulfikar";

  return (
    <button className="border-b-2 w-full text-left p-4 focus:outline-none focus-visible:bg-indigo-50">
      <div className="grid grid-cols-4 justify-between">
        <img
          crossOrigin="anonymous"
          className="rounded-full items-start flex-shrink-0 "
          src={imgUrl}
          width="40"
          height="40"
          alt="Marie Zulfikar"
        />

        <div className="col-span-2 -ml-6">
          <p className="font-medium text-md">{name}</p>
          <div className=" text-gray-400">Call ended</div>
        </div>
        <p className="text-base text-gra8-600">9:27 AM</p>
      </div>
    </button>
  );
};

const SingleUser = () => {
  return (
    <>
      <div className="h-full">
        <div className="relative max-w-[340px] mx-auto bg-white shadow-lg rounded-lg">
          {/* <div className="pt-2 relative mx-auto text-gray-600">
        <input className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
          type="search" name="search" placeholder="Search"/>
        <button type="submit" className="absolute right-0 top-0 mt-5 mr-4">
          <svg className="text-gray-600 h-4 w-4 fill-current" version="1.1" id="Capa_1" x="0px" y="0px"
            viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;"
            width="512px" height="512px">
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
          </svg>
        </button>
      </div>   */}
          <ImageComponent
            imgUrl="https://res.cloudinary.com/dc6deairt/image/upload/v1638102932/user-32-01_pfck4u.jpg"
            name="Renu"
          />
          <ImageComponent
            imgUrl="https://res.cloudinary.com/dc6deairt/image/upload/v1638102932/user-32-02_vll8uv.jpg"
            name="Lauren"
          />
          <ImageComponent
            imgUrl="https://res.cloudinary.com/dc6deairt/image/upload/v1638102932/user-32-03_uzwykl.jpg"
            name="Patrick Friedman"
          />
          <ImageComponent
            imgUrl="https://res.cloudinary.com/dc6deairt/image/upload/v1638102932/user-32-01_pfck4u.jpg"
            name="Seema"
          />
        </div>
      </div>
    </>
  );
};

export default SingleUser;
