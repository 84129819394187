import React, { useEffect, useState, Suspense } from "react";
import * as d3 from "d3";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
const ResumeTab = React.lazy(() => import("./Resume"));
// const FeedbackIndividual = React.lazy(() => import("./interview/FeedbackIndividual"));
import FeedbackIndividual from "./interview/FeedbackIndividual";
import MatchPercentChart from "../job/candidates/analytics/MatchPercentChart";
const core = require("../../../lib/core");
export default function ProfileSection({ checkResource = false }) {
  const profile = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile,
  );
  const { coverLetter, screeningAnswers } = useSelector((state) => {
    return {
      coverLetter:
        state?.jobCandidateMatch?.profile?.screeningQsnAns?.cover_latter,
      screeningAnswers:
        state?.jobCandidateMatch?.profile?.screeningQsnAns
          ?.screening_questions_answers,
    };
  });
  const appId = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile?._id,
  );
  const jobId = useSelector((state) => state?.JobExpandSlice?.profile?.id);
  const [width, setWidth] = useState(500);
  const [expShowAll, setExpShowAll] = useState(false);
  const [eduShowAll, setEduShowAll] = useState(false);
  const [eduList, setEduList] = useState([]);
  const [expList, setExpList] = useState([]);
  useEffect(() => {
    window.addEventListener("resize", setChartWidth);
    setChartWidth();
  }, [profile]);

  useEffect(() => {
    if (profile?.education?.length > 5)
      setEduList(
        (eduShowAll && profile?.education > 5) ||
          profile?.education?.length <= 5
          ? profile?.education
          : profile?.education.slice(0, 5),
      );
    else setEduList(profile?.education);
  }, [profile, eduShowAll]);

  const [analyticsData, setAnalyticsData] = useState([]);
  async function getSkills() {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_APPLICANT_ANALYTICS + appId + "/" + jobId,
        1,
      );
      const data = json?.data;
      setAnalyticsData({ ...analyticsData, [iter?._id]: data });
    } catch (error) {
      // Handle errors and return the error message
    }
  }
  useEffect(() => {
    getSkills();
  }, []);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (profile?.experience?.length > 5)
      setExpList(
        expShowAll ? profile?.experience : profile?.experience?.slice(0, 5),
      );
    else setExpList(profile?.experience);
    setLoading(false);
  }, [profile, expShowAll]);

  const checkLabel = (label1, label2) =>
    label1 ? label1 : label2 ? label2 : null;

  function setChartWidth() {
    try {
      if (
        d3.select("#candidateAnalytics")?.node()?.getBoundingClientRect().width
      ) {
        setWidth(
          Math.floor(
            d3.select("#candidateAnalytics")?.node()?.getBoundingClientRect()
              .width,
          ) ?? 500,
        );
      } else {
        setTimeout(() => {
          setWidth(500);
        }, 500);
      }
    } catch (err) {
      console.error(err);
      setChartWidth(500);
    }
  }
  const [currentTab, setCurrentTab] = useState("Resume");

  const renderSplitComponent = () => {
    switch (currentTab) {
      case "Resume":
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <ResumeTab />
          </Suspense>
        );
      case "Feedback":
        return (
          <FeedbackIndividual
            appId={appId}
            jobId={jobId}
            checkResource={checkResource}
          />
        );

      default:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <ResumeTab />
          </Suspense>
        );
    }
  };

  const stripHtmlTags = (html) => {
    if (!html) return "";
    // Create a temporary div element
    const temp = document.createElement("div");
    temp.innerHTML = html;
    // Return text content only
    return temp.textContent || temp.innerText;
  };

  // Check if the text contains HTML tags
  const hasHtmlTags = (text) => {
    const htmlRegex = /<[^>]*>/;
    return htmlRegex.test(text);
  };

  const displayText = hasHtmlTags(profile?.summary)
    ? stripHtmlTags(profile?.summary)
    : profile?.summary;
  return (
    <div className="w-full relative p-4 grow mb-4 flex flex-col items-start md:flex-row">
      <div className="relative w-0 h-0 flex justify-end">
        <div className="fixed top-1/6 -translate-y-1/2 z-[400] flex flex-col gap-4 mr-6">
          <button
            onClick={() => setCurrentTab("Resume")}
            type="button"
            className={`rounded-full p-4 bg-white ${currentTab !== "Resume" ? "text-gray-800" : "text-blue"} hover:text-blue group focus:outline-none focus:ring-2 focus:ring-white shadow-lg`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M13 9h5.5L13 3.5zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m8 18v-1c0-1.33-2.67-2-4-2s-4 .67-4 2v1zm-4-8a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"
              />
            </svg>
          </button>
          <button
            onClick={() => setCurrentTab("Feedback")}
            type="button"
            className={`rounded-full p-4 bg-white ${currentTab !== "Feedback" ? "text-gray-800" : "text-blue"} hover:text-blue group focus:outline-none focus:ring-2 focus:ring-white shadow-lg`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M9 22c-.6 0-1-.4-1-1v-3H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2h-6.1l-3.7 3.7c-.2.2-.4.3-.7.3zm1-6v3.1l3.1-3.1H20V4H4v12zm6.3-10l-1.4 3H17v4h-4V8.8L14.3 6zm-6 0L8.9 9H11v4H7V8.8L8.3 6z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="w-full md:w-[40%] pr-2 overflow-y-auto h-screen">
        <div className="flex items-center gap-2 my-2">
          {profile?.current_source && (
            <p className="px-2 py-1 rounded-md text-gray-800 bg-gray-300">
              {profile?.current_source}
            </p>
          )}
          {profile?.current_stage && (
            <p className="px-2 py-1 rounded-md text-yellow-800 bg-yellow-200">
              {profile?.current_stage}
            </p>
          )}
        </div>
        {profile?.summary?.length > 0 && (
          <div className="flex justify-start  flex-col ">
            <h3 className="text-base font-semibold mb-2 text-gray-800">
              Summary
            </h3>
            <p className=" text-sm text-justify text-[#5f6989] mt-2">
              <ExpandableText
                maxChar={300}
                classes="text-sm text-gray-800 leading-relaxed text-justify"
              >
                {displayText}
              </ExpandableText>
            </p>
          </div>
        )}

        <div className="flex flex-wrap justify-center items-center gap-6 my-3">
          {profile?.overallMatch > 0 && (
            <div className="flex flex-col justify-center items-center text-gray-800 font-semibold ">
              <MatchPercentChart
                value={profile?.overallMatch * 100}
                piewidth={100}
                textsize={15}
                showNumericValue={true}
              />{" "}
              <p>Overall match</p>
            </div>
          )}
          {profile?.skillsMatch > 0 && (
            <div className="flex flex-col justify-center items-center text-gray-800 font-semibold ">
              {" "}
              <MatchPercentChart
                value={profile?.skillsMatch * 100}
                piewidth={100}
                textsize={15}
                showNumericValue={true}
              />{" "}
              <p>Skills match</p>
            </div>
          )}
          {profile?.experienceMatch > 0 && (
            <div className="flex flex-col justify-center items-center text-gray-800 font-semibold">
              <MatchPercentChart
                value={profile?.experienceMatch * 100}
                piewidth={100}
                textsize={15}
                showNumericValue={true}
              />{" "}
              <p>Experience match</p>
            </div>
          )}
          {profile?.jobPositionMatch > 0 && (
            <div className="flex flex-col justify-center items-center text-gray-800 font-semibold">
              <MatchPercentChart
                value={profile?.jobPositionMatch * 100}
                piewidth={100}
                textsize={15}
                showNumericValue={true}
              />{" "}
              <p>Job position match</p>
            </div>
          )}
        </div>

        <section
          id="profile-section"
          className="w-full  grow  overflow-y-auto md:flex md:gap-4 relative flex-col"
        >
          <div
            // className={`${profile?.skills?.length > 0 ? "md:w-7/12 shrink" : null} w-full`}
            className={`w-full`}
          >
            <div className="my-3">
              {profile?.skills?.length > 0 && (
                <h3 className="text-base font-semibold mb-2 text-gray-800">
                  Skills
                </h3>
              )}
              <div className="flex items-center gap-2 flex-wrap">
                {profile?.skills?.length > 0 &&
                  profile?.skills.map((category, index) =>
                    category.skills.map((skillData, key) => (
                      <span
                        key={`${index}-${key}`}
                        className="text-[#252E4B] flex items-center text-[13px] not-italic font-normal inline-block py-[0.25rem] px-[1rem] bg-gray-300 rounded-md "
                      >
                        {skillData?.score === 0 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="red"
                            className="w-[18px] h-[18px] inline-block mr-2"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 10 5Zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : skillData?.score < 5 ? (
                          <svg
                            className="w-4 h-4 inline-block mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.81596 1.43012C5.3326 0.523294 6.67001 0.523292 7.18664 1.43012L11.662 9.28557C12.1642 10.1669 11.5106 11.25 10.4767 11.25H1.5259C0.491986 11.25 -0.161554 10.1669 0.34056 9.28557L4.81596 1.43012ZM6.5846 8.91667C6.5846 9.23883 6.32343 9.5 6.00127 9.5C5.6791 9.5 5.41793 9.23883 5.41793 8.91667C5.41793 8.5945 5.6791 8.33333 6.00127 8.33333C6.32343 8.33333 6.5846 8.5945 6.5846 8.91667ZM6.43877 4.25C6.43877 4.00838 6.24289 3.8125 6.00127 3.8125C5.75964 3.8125 5.56377 4.00838 5.56377 4.25V7.16667C5.56377 7.40829 5.75964 7.60417 6.00127 7.60417C6.24289 7.60417 6.43877 7.40829 6.43877 7.16667V4.25Z"
                              fill="#FF9500"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="w-4 h-4 inline-block mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M7 13.5625C10.6094 13.5625 13.5625 10.6094 13.5625 7C13.5625 3.39062 10.6094 0.4375 7 0.4375C3.39062 0.4375 0.4375 3.39062 0.4375 7C0.4375 10.6094 3.39062 13.5625 7 13.5625Z"
                              fill="#00AC85"
                            />
                            <path
                              d="M4.21094 6.99781L6.06812 8.855L9.78906 5.14062"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}

                        {skillData?.skills}
                      </span>
                    )),
                  )}
              </div>
            </div>

            <div className="my-2 bg-white">
              <h3 className="text-base font-semibold mb-2 text-gray-800">
                Education
              </h3>

              {eduList?.length > 0 ? (
                <div className="relative">
                  {eduList.map((edu, index) => (
                    <div key={index} className="mb-8 flex">
                      <div className="flex flex-col items-center mr-6">
                        <div className="w-4 h-4 bg-gray-600 rounded-full shadow-sm"></div>
                        {index !== eduList.length - 1 && (
                          <div className="w-0.5 h-full bg-gray-300 transform translate-x-1/2"></div>
                        )}
                      </div>
                      <div className="bg-gray-50 p-5 rounded-lg shadow-md flex-grow transition-all duration-300 hover:shadow-lg">
                        <div className="flex justify-between items-start mb-3">
                          <div>
                            <h4 className="font-semibold text-base">
                              {edu?.degree}
                            </h4>
                            <p className="text-gray-700 font-medium">
                              {edu?.school}
                            </p>
                          </div>
                          <p className="text-sm text-gray-600 bg-gray-200 px-3 py-1 rounded-full">
                            {edu?.ongoing
                              ? "Present"
                              : moment(edu.end).format("MMM YYYY")}
                          </p>
                        </div>
                        {edu?.description && (
                          <ExpandableText
                            maxChar={150}
                            classes="text-sm text-gray-600 leading-relaxed text-justify"
                          >
                            {edu?.description}
                          </ExpandableText>
                        )}
                        {edu?.gpa && edu?.gpa != 0 && (
                          <div className="mt-2 flex items-center gap-2">
                            <p className="font-semibold text-gray-700 ">GPA:</p>
                            <p className="text-gray-800 text-sm px-1">
                              {edu?.gpa}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-10">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      vectorEffect="non-scaling-stroke"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                  </svg>
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No education records
                  </h3>
                </div>
              )}
            </div>

            {/* Work experience section */}
            <div className="mt-2 bg-white">
              <h3 className="text-base font-semibold mb-2 text-gray-800">
                Work Experience
              </h3>
              {loading ? (
                <div className="flex justify-center items-center h-40">
                  <ScaleLoader
                    height={35}
                    width={4}
                    radius={2}
                    margin={2}
                    color="#1369e9"
                    loading={true}
                  />
                </div>
              ) : expList?.length > 0 ? (
                <div className="relative">
                  {expList.map((exp, index) => (
                    <div key={index} className="mb-8 flex">
                      <div className="flex flex-col items-center mr-6">
                        <div className="w-4 h-4 bg-gray-600 rounded-full shadow-sm"></div>
                        {index !== expList.length - 1 && (
                          <div className="w-0.5 h-full bg-gray-300 transform translate-x-1/2"></div>
                        )}
                      </div>
                      <div className="bg-gray-50 p-5 rounded-lg shadow-md flex-grow transition-all duration-300 hover:shadow-lg">
                        <div className="flex justify-between items-start mb-3">
                          <div>
                            <h4 className="font-semibold text-base">
                              {exp?.position}
                            </h4>
                            <p className="text-gray-700 font-medium">
                              {exp?.company}
                            </p>
                          </div>
                          <p className="text-sm text-gray-600 bg-gray-200 px-3 py-1 rounded-full">
                            {moment(exp?.start).format("MMM YYYY")} -{" "}
                            {exp?.ongoing
                              ? "Present"
                              : moment(exp?.end).format("MMM YYYY")}
                          </p>
                        </div>
                        {exp?.description && (
                          <ExpandableText
                            maxChar={150}
                            classes="text-sm text-gray-600 leading-relaxed text-justify"
                          >
                            {exp?.description}
                          </ExpandableText>
                        )}
                        {exp?.skills && exp?.skills.length > 0 && (
                          <div className="mt-4">
                            <p className="font-semibold text-gray-700 mb-2">
                              Skills:
                            </p>
                            <div className="flex flex-wrap gap-2">
                              {exp.skills.map((skill, skillIndex) => (
                                <span
                                  key={skillIndex}
                                  className="bg-gray-300 text-gray-800 text-sm px-3 py-1 rounded-lg"
                                >
                                  {skill}
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-10">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      vectorEffect="non-scaling-stroke"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                  </svg>
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No work experience
                  </h3>
                </div>
              )}
              {profile?.experience?.length > 5 && (
                <button
                  onClick={() => setExpShowAll(!expShowAll)}
                  className="w-full mt-4 py-2 text-blue-600 hover:text-blue-800 font-medium text-sm"
                >
                  {expShowAll ? "Show less" : "Show all experience"}
                </button>
              )}
            </div>

            {/* {screeningAnswers?.length > 0 ? (
            <div className="flex justify-start items-stretch flex-col mt-6">
              <h2 className="text-base font-medium text-[#16182f] mb-3">
                Screening Question response
              </h2>

              {screeningAnswers?.map((question, index) => (
                <div
                  key={index}
                  className="w-full flex flex-col items-start mb-3"
                >
                  <span
                    className={`w-full block text-gray-800 font-medium text-sm`}
                  >
                    {`Q${index + 1} : ${question?.question}`}
                  </span>
                  <span className="text-sm text-gray-700 font-semibold w-full">{`Answer : ${question?.candidateResponse}`}</span>
                </div>
              ))}
            </div>
          ) : null} */}
          </div>
        </section>
      </div>
      <div className="w-full md:w-[60%] px-2 md:mt-0 overflow-y-auto h-screen">
        <div key={currentTab}>{renderSplitComponent()}</div>
      </div>
    </div>
  );
}

function ExpandableText({ children, maxChar = 150, classes }) {
  const [expand, setExpand] = useState(false);

  useEffect(() => {}, [expand]);
  return (
    children && (
      <p className={classes}>
        {children?.length <= maxChar ? (
          children
        ) : expand ? (
          <>
            <span>{children}</span>
            <button
              className="ml-2 p-1 rounded hover:text-blue-700 text-blue-600 underline underline-offset-2"
              onClick={() => setExpand(false)}
            >
              See less
            </button>
          </>
        ) : (
          <>
            <span>{String(children).substring(0, maxChar)} ...</span>
            <button
              className="ml-2 px-1 rounded hover:text-blue-700 text-blue-600 underline underline-offset-2"
              onClick={() => setExpand(true)}
            >
              See more
            </button>
          </>
        )}
      </p>
    )
  );
}

// [{"name":"Background check","status":true,"question":"Are you willing to undergo a background check, in accordance with local law/regulations?","required":false,"answerType":"yes/no","id":0,"candidateResponse":"Yes"},{"name":"Industry experience","status":true,"question":"Mention your experience in the industry (in years)","required":false,"answerType":"number","id":1,"candidateResponse":"4"},{"name":"Relocate","status":true,"question":"Will you be able to reliably commute or relocate to the job location?","required":false,"answerType":"yes/no","id":3,"candidateResponse":"Yes"}]
