import React, { useContext, useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import { ScaleLoader } from "react-spinners";
import TextAreaBox from "../../../layouts/TextAreaBox";
import InitialsLogo from "../../all/InitialsLogo";
import { convertToHTML } from "draft-convert";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
const core = require("../../../lib/core");

const AddFollowupModal = ({
  candidateDetail,
  jobId,
  chatDetail,
  companydata,
  modalDismiss,
  setContentReload,
}) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});

  function formatDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDate = `${day} ${months[monthIndex]} ${year} ${hours}:${minutes}`;
    return formattedDate;
  }

  const { user } = useContext(core.UserContext);
  useEffect(() => {
    if (candidateDetail?.name === undefined) {
      setNotes([]);
    } else {
      setNotes(
        candidateDetail?.name.length > 0 &&
          candidateDetail?.name[0]?.name?.length > 0
          ? candidateDetail?.name
          : Array.isArray(candidateDetail?.name)
            ? candidateDetail?.name
            : [
                {
                  note: candidateDetail?.name,
                  name: user?.name ?? user?.email,
                  date: new Date().toLocaleString(),
                },
              ],
      );
    }
  }, [candidateDetail?.id]);

  const [text, setText] = useState("");

  const updateNotes = async (type) => {
    try {
      setMessage({});
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_MATCHPROFILE_FEEDBACK,
        1,
        {
          jobId: jobId,
          matchProfileId: candidateDetail?.id,
          thumbs_up: candidateDetail?.vote === false ? 2 : 1,
          notes:
            text.length > 0
              ? [
                  ...notes,
                  {
                    note: text,
                    name: user?.name ?? user?.email,
                    date: new Date(),
                  },
                ]
              : notes,
        },
      );
      if (json?.data) {
        setLoading(false);
        setContentReload(json?.data);
        modalDismiss();

        if (text.length > 0) {
          const newNote = {
            note: text,
            name: user.name ?? user.email,
            date: new Date().toLocaleString(),
          };

          setNotes((prevNotes) => [...prevNotes, newNote]);
          setText("");
        }
      } else {
        setLoading(false);
        setMessage({
          type: "error",
          message: json?.error?.message,
        });
      }
    } catch (error) {
      setLoading(false);
      setMessage({
        type: "error",
        message: "Something went wrong. Please try again",
      });
    }
  };

  const handleDeleteNote = (index) => {
    setNotes((prevNotes) => {
      const updatedNotes = [...prevNotes];
      updatedNotes.splice(index, 1);
      return updatedNotes;
    });
  };

  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );
  const [editorValue, setEditorValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const [subject, setSubject] = useState([]);

  const { position } = useSelector((state) => {
    return {
      position: state?.JobExpandSlice?.profile?.position,
    };
  });

  useEffect(() => {
    if (chatDetail?.email?.length > 0) {
      setTo(chatDetail?.email[0]);
    }
  }, [chatDetail]);

  const [emailSuccess, setEmailSuccess] = useState(false);

  // Array of sample options (you can fetch these dynamically)
  const sampleOptions = [];

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleOptionClick = (option) => {
    setSelectedOptions([...selectedOptions, option]);
    setInputValue(""); // Clear input after selecting an option
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
  };
  console.log("chatDetail", chatDetail);
  useEffect(() => {
    setEditorValue(convertToHTML(editorStateDesc?.getCurrentContent()));
  }, [editorStateDesc]);

  const [sendText, setSendingText] = useState("Send email");

  console.log(to);

  async function sendEmail() {
    setSendingText("Sending...");
    await core
      .API(core.API_METHODS.POST, core.RECRUITER_API.POST_EMAIL_CANDIDATE, 1, {
        to: to,
        cc: selectedOptions,
        body: editorValue,
        subject: subject,
        matchprofile: chatDetail?._id,
      })
      .then((json) => {
        setEmailSuccess(true);
        setSubject();
        setSendingText("Send email");
      });
  }

  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
      );
      // If user exists
      if (json?.data?.user) {
        setSelectedOptions([...selectedOptions, json?.data?.user?.email]);
      } else {
      }
    } catch (err) {}
  }

  const getTemplate = async () => {
    setSubject("");
    setEditorStateDesc(
      EditorState?.createWithContent(
        ContentState?.createFromBlockArray(
          convertFromHTML(new String("Generating message...").toString()),
        ),
      ),
    );
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_EMAIL_TEMPLATE,
        1,
        {
          company_name: companydata,
          job_position: position,
          candidate_name: chatDetail?.first_name,
          candidate_matching_comment: chatDetail?.summary,
          matchId: chatDetail?._id,
        },
      );
      console.log(chatDetail);
      if (json?.data) {
        setSubject(json?.data?.data?.subject);
        if (typeof json?.data?.data?.body == "string") {
          setEditorStateDesc(
            EditorState?.createWithContent(
              ContentState?.createFromBlockArray(
                convertFromHTML(
                  new String(
                    json?.data?.data?.body?.split("&lt;").join("<"),
                  ).toString(),
                ),
              ),
            ),
          );
        } else {
          setEditorStateDesc(
            EditorState?.createWithContent(
              ContentState?.createFromBlockArray(
                convertFromHTML(new String("Failed to generate").toString()),
              ),
            ),
          );
        }
      }

      if (json?.error) {
        setEditorStateDesc(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(new String("Failed to generate").toString()),
            ),
          ),
        );
      }
    } catch (error) {
      setEditorStateDesc(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(new String("Failed to generate").toString()),
          ),
        ),
      );
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getTemplate();
    setEmailSuccess(false);
  }, [chatDetail]);

  return (
    <Modal
      heading={"Notes"}
      subheading={"Add feedback for this candidate"}
      onSave={updateNotes}
      className={"md:max-w-[60%]"}
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryDisabled: loading,
        primaryLabel: "Save",
      }}
      isLoading={loading}
    >
      <div className="">
        <div>
          {notes &&
            notes?.map((note, index) => (
              <div key={index} className="mb-4  bg-gray-100 rounded">
                <div className="flex gap-4">
                  <InitialsLogo
                    str={note?.name}
                    borderRadius="50%"
                    width="30px"
                    height="30px"
                  />
                  <div className="flex flex-col">
                    <span className="flex items-center gap-4">
                      {" "}
                      <span
                        className=" text-sm not-italic  text-gray-800 truncate
font-semibold"
                      >
                        {note.name}
                      </span>{" "}
                      -{" "}
                      <span
                        className=" text-sm
not-italic  text-gray-800 truncate font-medium"
                      >
                        {note?.date && formatDate(note?.date)}
                      </span>
                      <button
                        onClick={() => handleDeleteNote(index)}
                        className=" cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <path
                            d="M3.33333 5.83398V12.5007C3.33333 13.9734
4.52724 15.1673 6 15.1673H10C11.4728 15.1673 12.6667 13.9734 12.6667
12.5007V5.83398M9.33333 7.83398V11.834M6.66667 7.83398L6.66667
11.834M10.6667 3.83398L9.72916 2.42772C9.48187 2.05679 9.06556 1.83398
8.61975 1.83398H7.38025C6.93444 1.83398 6.51813 2.05679 6.27084
2.42772L5.33333 3.83398M10.6667 3.83398H5.33333M10.6667
3.83398H14M5.33333 3.83398H2"
                            stroke="#FF475D"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </span>
                    <span className="text-sm text-[#5F6989] ">{note.note}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className=" ">
          <TextAreaBox
            placeholder="Write a note here ..."
            value={text}
            name=""
            id=""
            onChange={(e) => setText(e.target?.value)}
          />
        </div>
      </div>

      <>
        <div className="">
          <h5 className="text-black text-base font-medium w-[calc(100%-32px)] not-italic">
            Send email to {to}
          </h5>
          <span className="text-gray-700 text-sm -mt-3 not-italic font-normal">
            Verify the email's content and then proceed to send it to the
            candidate.
          </span>
        </div>

        <div className="">
          {/* <div className=' mt-[20px]'>
                            <label className=' text-right text-[14px] font-normal not-italic required' htmlFor="">To</label>
                            <input value={to} required className='border  py-[11px] border-[#E1E1EE] bg-[#FFF] px-[20px] rounded-[8px] w-full' type="email"
                                onChange={e => setTo(e.target.value)}
                            />
                        </div> */}
          <div className="  mt-[20px]">
            <label
              className="  text-[14px] font-normal not-italic required"
              htmlFor=""
            >
              Subject
            </label>
            <input
              className="border  border-[#E1E1EE] py-[11px] bg-[#FFF] px-[20px] rounded-[8px] w-full"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e?.target?.value)}
            />
          </div>
          <div className=" mt-[20px]">
            <label className=" text-[14px] font-normal not-italic" htmlFor="">
              Cc
            </label>
            <div className="relative border flex flex-wrap py-[11px]  border-[#E1E1EE] bg-[#FFF]  rounded-[8px] w-full px-[20px] ">
              {selectedOptions.map((option, index) => (
                <span
                  className="h-[20px] flex items-center mr-[10px] rounded-[4px] bg-[#F3F5F9] px-[10px]"
                  key={index}
                >
                  {option}
                  <svg
                    className="cursor-pointer"
                    onClick={(e) => handleRemoveOption(index)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7 7L17 17"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 17L17 7"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              ))}
              <div className="relative">
                <input
                  type="email"
                  className="w-auto"
                  value={inputValue}
                  onChange={handleInputChange}
                />

                {inputValue?.length > 2 && (
                  <ul className="absolute bg-[#F3F5F9] px-[10px] py-[5px] rounded-[5px] top-[24px]">
                    <li onClick={() => handleOptionClick(inputValue)}>
                      {inputValue}
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="mt-[20px]" id="description">
            <label
              className=" text-right text-[14px] mt-[15px] font-normal not-italic"
              htmlFor=""
            >
              Body
            </label>
            <Editor
              editorState={editorStateDesc}
              onEditorStateChange={setEditorStateDesc}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            />
          </div>
        </div>

        <div className="flex justify-end mt-[20px]">
          <button
            disabled={
              sendText === "Sending..." ||
              editorValue === "Generating message..." ||
              subject?.length === 0
                ? true
                : false
            }
            onClick={sendEmail}
            className="solid-button-blue px-[20px] h-[40px] rounded-[8px] bg-[#1369E9]  shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] not-italic font-normal"
          >
            {sendText}
          </button>
        </div>
      </>
    </Modal>
  );
};

export default AddFollowupModal;
