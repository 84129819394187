import React, { useState } from "react";
import Modal from "../../modal/Modal";
import InputBox from "../../../layouts/InputBox";
const core = require("../../../lib/core");

function FeedbackShareModal({ modalDismiss, feedback_id }) {
  const [emails, setEmails] = useState([]); // List of shared emails
  const [inputValue, setInputValue] = useState(""); // Current input value
  const [message, setMessage] = useState({});

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const POST_SHARE_FEEDBACKREPORT = async () => {
    const payload = {
      interview_id: feedback_id, // Pass the relevant interview ID
      email: inputValue, // An array of emails
    };

    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_SHARE_FEEDBACKREPORT,
        1,
        payload,
      );

      if (json?.data) {
        setMessage({
          type: "success",
          message: "Feedback shared successfully",
        });
        setTimeout(() => {
          setMessage({});
          modalDismiss();
        }, 2000);
      } else {
        setMessage({
          type: "error",
          message: "Failed to share feedback. Please try again.",
        });
      }
    } catch (error) {
      console.error("API call failed", error);
    }
  };

  const modalElements = {
    heading: "Share Downloaded Feedback Form",
    subheading: "Enter email address to share downloaded feedback form",
    modalDismiss: () => modalDismiss(),
    onSave: () => POST_SHARE_FEEDBACKREPORT(),
    defaultButtons: {
      primaryLabel: "Share",
      primaryDisabled: !isValidEmail(inputValue),
    },
  };

  return (
    <Modal {...modalElements}>
      <div className="w-full flex items-center gap-2">
        <InputBox
          type="email"
          placeholder="Enter email address"
          className={`w-full border px-4 py-2 rounded-md text-sm ${
            inputValue && !isValidEmail(inputValue)
              ? "border-red-500"
              : "border-gray-400"
          }`}
          value={inputValue}
          onChange={(e) => {
            const email = e.target.value;
            setInputValue(email);

            // Inline validation for email
            if (email && !isValidEmail(email)) {
              setMessage({
                type: "error",
                message: "Invalid email address format.",
              });
            } else {
              setMessage({});
            }
          }}
        />
      </div>
      {Object.entries(message).length > 0 && (
        <p
          style={
            message?.type === "error" ? { color: "red" } : { color: "green" }
          }
          className="block text-sm font-medium mt-2"
        >
          {message?.message}
        </p>
      )}
    </Modal>
  );
}

export default FeedbackShareModal;
