import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { convertToHTML } from "draft-convert";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Modal from "../../../modal/Modal";
import InitialsLogo from "../../../all/InitialsLogo";
import SearchUser from "../../user/SearchEmails";
import InputBox from "../../../../layouts/InputBox";
import { useStatusModal } from "../../../../App";
const core = require("../../../../lib/core");

function FeedbackInviteEmailModal({
  chatDetail,
  companydata,
  positionData,
  modalDismiss,
  job_Id,
  appId,
}) {
  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );
  const [editorValue, setEditorValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const [subject, setSubject] = useState([]);
  const profile = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile,
  );
  function generateCandidateHTML(candidates) {
    return candidates
      .map(
        (candidate) => `
      <div class="candidate">
        <div class="candidate-info">
          <div class="candidate-name">${candidate.name}</div>
          <div class="candidate-title">${candidate.title}</div>
        </div>
        <a href="${candidate.viewLink}" class="view-button">View Candidate</a>
      </div>
    `,
      )
      .join("");
  }

  function sendCandidateEmail(candidates) {
    const candidateListHTML = generateCandidateHTML(candidates);

    const htmlContent = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Candidate List</title>
      <style>
          body {
              font-family: Arial, sans-serif;
              line-height: 1.6;
              color: #333;
              max-width: 600px;
              margin: 0 auto;
              padding: 20px;
          }
          .header {
              text-align: center;
              margin-bottom: 20px;
          }
          .logo {
              max-width: 150px;
              height: auto;
          }
          .company-name {
              font-size: 24px;
              font-weight: bold;
              margin-top: 10px;
          }
          .candidate {
              background-color: #f9f9f9;
              border: 1px solid #ddd;
              padding: 15px;
              margin-bottom: 15px;
              border-radius: 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
          }
          .candidate-info {
              flex-grow: 1;
          }
          .candidate-name {
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 5px;
          }
          .candidate-title {
              font-style: italic;
              color: #666;
          }
          .view-button {
              display: inline-block;
              background-color: #4CAF50;
              color: white;
              padding: 10px 20px;
              text-decoration: none;
              border-radius: 3px;
              font-weight: bold;
              text-align: center;
              min-width: 120px;
          }
      </style>
  </head>
  <body>
      <div class="header">
          <img src="${""}" alt="${"Sproutsai"} Logo" class="logo">
          <div class="company-name">${"Sproutsai"}</div>
      </div>
  
      <h1>Candidate List</h1>
  
      <p>Here are the latest candidates for your review:</p>
  
      ${candidateListHTML}
  
      <p>If you have any questions, please don't hesitate to contact us.</p>
  
      <p>Best regards,<br>Your Recruitment Team</p>
  </body>
  </html>
    `;

    return htmlContent;
  }

  // Example usage
  const candidates = [
    {
      name: "John Doe",
      title: "Senior Software Engineer",
      viewLink: "https://www.example.com/view/john-doe",
    },
    {
      name: "Jane Smith",
      title: "UX Designer",
      viewLink: "https://www.example.com/view/jane-smith",
    },
    {
      name: "Mike Johnson",
      title: "Product Manager",
      viewLink: "https://www.example.com/view/mike-johnson",
    },
  ];

  // sendCandidateEmail(candidates);

  const { position, jobId } = useSelector((state) => {
    return {
      position: state?.JobExpandSlice?.profile?.position,
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  useEffect(() => {
    if (chatDetail?.email?.length > 0) {
      setTo(chatDetail?.email[0]);
    }
  }, [chatDetail]);

  const [emailSuccess, setEmailSuccess] = useState(false);

  useEffect(() => {
    setEditorValue(convertToHTML(editorStateDesc?.getCurrentContent()));
  }, [editorStateDesc]);

  const [sendText, setSendingText] = useState("Send email");
  const { showSuccess, showError } = useStatusModal();
  const [loading, setLoading] = useState(false);
  async function sendEmail() {
    setLoading(true);
    setSendingText("Sending...");
    const formattedEmails = Array.isArray(emails) ? emails.join(",") : emails;
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_EMAIL_CANDIDATE,
        1,
        {
          to: formattedEmails,
          cc: selectedOptions,
          body: editorValue,
          subject: subject,
          matchprofile: profile?._id,
          applicant: appId,
          job: jobId,
        },
      );

      if (json) {
        setEmailSuccess(true);
        setSubject("");
        setSendingText("Send email");
        setLoading(false);
        modalDismiss();
        showSuccess("Email sent successfully", "");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setLoading(false);
      modalDismiss();
      showError(
        "Email sending error",
        "An error occurred while sending the email. Please try again.",
      );
    } finally {
      setLoading(false);
      setSendingText("Send email");
    }
  }

  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
      );
      // If user exists
      if (json?.data?.user) {
        setSelectedOptions([...selectedOptions, json?.data?.user?.email]);
      } else {
      }
    } catch (err) {}
  }

  const { user } = useContext(core.UserContext);
  const loc = window.location;
  const [url, setUrl] = useState(`${loc.origin}/feedback/${job_Id}/${appId}`);
  const getTemplate = async () => {
    setSubject("Request for Feedback on Candidate Profile");
    setEditorStateDesc(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            `<div class="container">
                        <div class="header">
                            <h1>Feedback Request</h1>
                        </div>
                        <div class="content">
                            <p>Dear Team Member,</p>
                            <p>We hope this email finds you well.</p>
                            <p>We would like to invite you to provide feedback on the profile or interview of our candidate, ${profile?.first_name} ${profile?.last_name}. Your insights and professional evaluation are highly valuable to us.</p>
                            <p>Please click the link below to access the candidate's profile </p>
                            <p>If you have any questions or need further information, please do not hesitate to contact us.</p>
                            <a href="${url}">${url}</a>
                            <p>Thank you for your time and assistance.</p>
                            <p>Best regards,</p>
                            <p>${user?.name}<br></p>
                        </div>
                    </div>`,
          ),
        ),
      ),
    );
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getTemplate();
    setEmailSuccess(false);
  }, [chatDetail]);

  const [emails, setEmails] = useState([]);

  const addInterviewer = (email) => {
    setEmails([...emails, email]);
  };

  return (
    <Modal
      modalDismiss={modalDismiss}
      // noBtns={!emailSuccess}
      heading={"Invite for feedback"}
      subheading={"Invite your team member or share this URL for feedback."}
      className={"md:max-w-[60%]"}
      onSave={() => {
        sendEmail(); // Call the original onSubmit function
      }}
      defaultButtons={{
        primaryClass: `bg-[#1369E9] px-[20px] h-[40px] rounded-[8px] shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] font-normal`,
        primaryLabel: <span>{sendText}</span>,
        primaryDisabled:
          sendText === "Sending..." ||
          editorValue === "Generating message..." ||
          subject?.length === 0 ||
          emails.length === 0
            ? true
            : false,
      }}
      isLoading={loading}
    >
      <div className="">
        <div className="  mt-[20px]">
          <label
            className="  text-[14px] font-normal not-italic required"
            htmlFor=""
          >
            Subject
          </label>
          <InputBox
            className="border  border-[#E1E1EE] py-[11px] bg-[#FFF] px-[20px] rounded-[8px]"
            type="text"
            width="100%"
            value={subject}
            onChange={(e) => setSubject(e?.target?.value)}
          />
        </div>

        <SearchUser label={"To"} emails={emails} setEmails={setEmails} />

        <div className="mt-[20px]" id="description">
          <label
            className=" text-right text-[14px] mt-[15px] font-normal not-italic"
            htmlFor=""
          >
            Body
          </label>
          <Editor
            editorState={editorStateDesc}
            onEditorStateChange={setEditorStateDesc}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
        </div>
      </div>
    </Modal>
  );
}

export default FeedbackInviteEmailModal;
