import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { sproutsCandidateService } from "../../../redux/services";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import AddOutreachCandidateModal from "../../job/AddOutreachCandidateModal";
import AddUrlCandidates from "../../job/AddUrlCandidates";
import StatusModal from "../../../screens/recruiter/ResumeStatus";
import FileUploader from "../../all/FileUploader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useContext } from "react";
import { BsPersonGear } from "react-icons/bs";
import ChangeCandidatesDataModal from "../job/candidates/ChangeCandidatesDataModal";
import ProtectedComponent from "../../../layouts/ProtectedComponent";

const core = require("../../../lib/core");

function ImportCandidateButton({ hidePart = false, pipelines }) {
  const fileInputRef = useRef(null);

  const [showUploader, setShowUploader] = useState(false);
  const [resumeName, setResumeName] = useState();
  const [resumeFileName, setResumeFileName] = useState("Upload Resume");
  const [resumeFile, setResumeFile] = useState("Upload Resume");
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [resumeData, setResumeData] = useState();
  const [applicantData, setApplicantData] = useState();
  const [disbaleUploadcandidate, setDisableUploadcandidate] = useState(false);
  const [showModal, setShowModal] = useState("");
  const [showCandidateModal, setShowCandidateModal] = useState(false);
  const [inputdata, setInputdata] = useState({
    name: "",
    email: "",
    resumeLink: "",
    jobId: "",
  });
  const [showfoldermenu, setShowfoldermenu] = useState(false);
  // const [currentLoc, setCurrentLoc] = useState();

  const [params, setParams] = useState(useParams());

  function setInput(key, value) {
    setInputdata({ ...inputdata, [key]: value });
  }
  const jobId = useSelector((state) => state?.JobExpandSlice?.profile?.id);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const getSproutsCandidates = () => {
    dispatch(sproutsCandidateService("?jobId=" + params?.jobId));
  };

  async function add_applicant(e) {
    setLoadingAdd(true);
    setDisableUploadcandidate(true);
    e.preventDefault();
    let response = await core.API(
      core.API_METHODS.POST,
      core.RECRUITER_API.POST_UPLOAD_CANDIDATE,
      1,
      {
        job: jobId,
        // job: currentLoc.split("/").at(-1).split("&")[0],
        resume: resumeData,
        resumeLink: inputdata.resumeLink,
        name: inputdata.name,
        email: inputdata.email,
      },
    );

    if (response?.data) {
      // setLoadingAdd(false);
      window.location.reload();
    } else {
      setDisableUploadcandidate(false);
    }
  }
  const [dataUploaded, setDataUploaded] = useState(false);
  const [disbaleUploadResume, setDisableUploadresume] = useState(false);

  function onFileChange(file) {
    try {
      setResumeFile(Array.from(file));
      setDataUploaded(false);
      setDisableUploadresume(false);
      setResumeFileName(Array.from(file));
      setShowUploader(true);
      // setCurrentLoc(jobIdpath);

      const selectedFile = file;
      fileInputRef.current.value = ""; // Clear the input value
      const event = new CustomEvent("change", { detail: selectedFile });
      fileInputRef.current.dispatchEvent(event);
    } catch (err) {
      // setResumeBtnName("Upload Resume");
    }
  }

  useEffect(() => {
    // Add a click event listener to the document
    const handleDocumentClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        // Clicked outside of the menu, close the menu
        setShowfoldermenu(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const { user, resources } = useContext(core.UserContext);

  return (
    <>
      <div className="w-auto import-button-section menu-button-section inline-flex gap-4">
        <div ref={dropdownRef} className="relative">
          <button
            className="border-button w-10 text-center btn-md mx-auto p-0 bg-white rounded-lg shadow border border-gray-300"
            onClick={() => setShowCandidateModal(true)}
            data-tooltip-id="my-tooltip-8"
            data-tooltip-content={"Add candidate"}
          >
            <svg
              className="relative inline-block"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M5.29492 12.7042C8.02254 11.4822 9.64654 11.4572 12.7023 12.7042"
                stroke="#252E4B"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle
                cx="2.77778"
                cy="2.77778"
                r="2.77778"
                transform="matrix(1 0 0 -1 6.22266 9)"
                stroke="#252E4B"
                strokeWidth="1.2"
                strokeLinejoin="round"
              />
              <path
                d="M17.1262 10.8522C17.2613 10.2566 17.3327 9.6368 17.3327 9.00033C17.3327 4.39795 13.6017 0.666992 8.99935 0.666992C4.39698 0.666992 0.666016 4.39795 0.666016 9.00033C0.666016 13.6027 4.39698 17.3337 8.99935 17.3337C9.63582 17.3337 10.2556 17.2623 10.8512 17.1272"
                stroke="#252E4B"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.4805 13.6289V17.3326"
                stroke="#252E4B"
                strokeWidth="1.2"
                strokeLinecap="round"
              />
              <path
                d="M17.332 15.4814L13.6283 15.4814"
                stroke="#252E4B"
                strokeWidth="1.2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>

        <ReactTooltip
          id="my-tooltip-8"
          noArrow={true}
          style={{
            zIndex: 9999,
            background: "#5F6989",
            fontSize: "10px",
            padding: "4px 8px",
            fontWeight: "400",
          }}
          place="bottom"
        />

        {showModal == "outreach-candidate-modal" && (
          <AddOutreachCandidateModal modalDismiss={() => setShowModal("")} />
        )}
        {showModal == "add-Url-candidate-modal" && (
          <AddUrlCandidates
            modalDismiss={() => setShowModal("")}
            jobId={jobId}
          />
        )}

        {!hidePart && (
          <ProtectedComponent
            showModal={true}
            modalProps={{
              title: "Permission Required",
              description: "You need permission to view candidate status.",
              requiredPermission: "Candidate_show_status",
              status: "Restricted",
            }}
            code="Candidate_show_status"
          >
            <StatusModal
              className={
                "w-10 btn-md px-2 mx-auto bg-white rounded-lg shadow border border-gray-300 border-button inline-flex items-center justify-center p-0"
              }
              jobid={jobId}
            />
          </ProtectedComponent>
        )}

        <input
          type="file"
          className="upload hide"
          id="folder"
          name="folder"
          webkitdirectory="true"
          onChange={(e) => {
            onFileChange(e.target.files);
            setResumeName(e.target.files[0]?.name);
          }}
        />

        <input
          type="file"
          className="upload hide"
          id="resume"
          multiple
          ref={fileInputRef}
          name="resume"
          onChange={(e) => {
            onFileChange(e.target.files);
            setResumeName(e.target.files[0]?.name);
          }}
        />
      </div>
      {showUploader && (
        <FileUploader
          currentPath={jobId}
          disbaleUploadResume={disbaleUploadResume}
          setDisableUploadresume={setDisableUploadresume}
          inputdata={inputdata}
          setInputdata={setInputdata}
          setInput={setInput}
          add_applicant={add_applicant}
          setApplicantData={setApplicantData}
          setResumeData={setResumeData}
          applicantData={applicantData}
          resumeFile={resumeFile}
          loadingAdd={loadingAdd}
          dataUploaded={dataUploaded}
          setDataUploaded={setDataUploaded}
          resumeFileName={resumeFileName}
          setShowUploader={setShowUploader}
          disbaleUploadcandidate={disbaleUploadcandidate}
        />
      )}

      {showCandidateModal ? (
        <ChangeCandidatesDataModal
          from="import"
          modalDismiss={() => setShowCandidateModal(false)}
          jobId={jobId}
          pipelines={pipelines}
        />
      ) : null}
    </>
  );
}

export default ImportCandidateButton;
