import React, { useState } from "react";
import Modal from "../../modal/Modal";
import { useNavigate } from "react-router";
import { ScaleLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { fetchJobDetails } from "../../../redux/services";
import { useStatusModal } from "../../../App";

const core = require("../../../lib/core");

function CloneJobModal({ modalDismiss, jobId }) {
  const dispatch = useDispatch();
  const { showSuccess, showError } = useStatusModal();

  const onCancel = () => {
    modalDismiss();
  };

  const nav = useNavigate();
  const [loading, setLoading] = useState(false);

  const clonesimilerjob = async () => {
    setLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_CLONE_JOB + jobId,
        // jobData?.id,
        1,
        {},
      );
      if (json.data?.jobId) {
        let newJobId = json.data?.jobId;
        setLoading(false);
        modalDismiss();
        showSuccess("Job Cloned Successfully");

        // settime out
        setTimeout(() => {
          nav(`/job/active/${newJobId}/description`);
          window.location.reload();
        }, 2000);
      }
      setLoading(false);
      modalDismiss();
      showSuccess("Job Cloned Successfully");
    } catch (error) {
      setLoading(false);
      modalDismiss();
      showError("Failed to Clone Job");
    }
  };

  const modalElements = {
    heading: "Clone Job",
    subheading:
      "Duplicate an existing job for easy replication and modification",
    modalDismiss: () => onCancel(),
    defaultButtons: {
      primaryLabel: "Clone job",
      secLabel: "Cancel",
    },
    onSave: () => {
      clonesimilerjob();
    },
    isLoading: loading,
  };
  return (
    <Modal {...modalElements}>
      <div className="w-full flex flex-col">
        {/* <div className='flex items-center '>
                    <input type='checkbox' className='mb-[5px]'/>
                    <label className='mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic'>Clone applicants</label>
                </div> */}
      </div>
    </Modal>
  );
}

export default CloneJobModal;
