import Modal from "./Modal";
import { BiCheck, BiX } from "react-icons/bi";

export function SuccessModal({ heading, subheading, onDismiss, className }) {
  return (
    <Modal
      heading=""
      modalDismiss={onDismiss}
      noBtns={true}
      className={`w-[30%] max-w-[600px] ${className}`}
    >
      <div
        className={`w-full p-4 -mt-6 pb-6 ${
          subheading
            ? "grid grid-cols-[60px_1fr] gap-y-2 gap-x-4 "
            : "flex items-center justify-start gap-x-2 gap-y-0"
        } `}
      >
        <div
          className={`${
            subheading ? "row-span-2" : "mr-4"
          } flex items-center justify-center bg-[#00AC85] rounded-full w-16 h-16`}
        >
          <BiCheck size={34} className="text-white stroke-[1.5]" />
        </div>
        <h1 className="text-black text-base font-medium">
          {heading?.charAt(0).toUpperCase() + heading?.slice(1).toLowerCase()}
        </h1>
        {subheading && (
          <span className="text-gray-700 text-sm not-italic font-normal">
            {subheading?.charAt(0).toUpperCase() +
              subheading?.slice(1).toLowerCase()}
          </span>
        )}
      </div>
    </Modal>
  );
}

export function ErrorModal({ heading, subheading, onDismiss, className }) {
  return (
    <Modal
      heading=""
      modalDismiss={onDismiss}
      noBtns={true}
      className={`w-[30%] max-w-[600px] ${className}`}
    >
      <div
        className={`w-full p-4 -mt-6 pb-6 ${
          subheading
            ? "grid grid-cols-[60px_1fr] gap-y-2 gap-x-4"
            : "flex items-center justify-start gap-x-2 gap-y-0"
        }`}
      >
        <div
          className={`${
            subheading ? "row-span-2" : "mr-4"
          } flex items-center justify-center bg-[#DC2626] rounded-full w-16 h-16`}
        >
          <BiX size={34} className="text-white stroke-[1.5]" />
        </div>
        <h1 className="text-black text-base font-medium">
          {heading?.charAt(0).toUpperCase() + heading?.slice(1).toLowerCase()}
        </h1>
        {subheading && (
          <span className="text-gray-700 text-sm not-italic font-normal">
            {subheading?.charAt(0).toUpperCase() +
              subheading?.slice(1).toLowerCase()}
          </span>
        )}
      </div>
    </Modal>
  );
}
