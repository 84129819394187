import React, { useEffect, useState } from "react";
import Modal from "../../../modal/Modal";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
const core = require("../../../../lib/core");

const SkillItem = ({ skill, isSelected, onToggle }) => (
  <button
    className={`m-1 px-3 py-2 rounded-lg text-sm font-medium flex items-center justify-between transition-colors ${
      isSelected
        ? " bg-gray-300 text-gray-800 hover:bg-gray-400"
        : "border border-gray-300 text-gray-800 hover:bg-gray-200"
    }`}
    onClick={onToggle}
  >
    <span>{skill}</span>
  </button>
);

function ViewMcqQsn({ modalDismiss }) {
  const [skillGroups, setSkillGroups] = useState([]);

  const { jobId } = useSelector((state) => {
    return {
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  const fetchSkillsGroup = async () => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_MCQ_QUESTION_STATUS + jobId,
        1,
        {},
      );
      if (response?.data) {
        if (response?.data?.mcq_criteria_status) {
          setSkillGroups(response?.data?.mcq_criteria_status);
        }
      } else {
        console.error("Failed to fetch skills group", response);
      }
    } catch (error) {
      console.error("Failed to fetch skills group", error);
    }
  };

  useEffect(() => {
    fetchSkillsGroup();
  }, []);

  //   const questions = [
  //     {
  //       question: "What does the `map()` method do in JavaScript?",
  //       choice_1:
  //         "It creates a new array with only the elements that pass a provided test.",
  //       choice_2:
  //         "It creates a new array with elements that have been transformed by a provided function.",
  //       choice_3:
  //         "It creates a new array with elements that have been filtered out based on a provided condition.",
  //       choice_4:
  //         "It creates a new array with elements that have been sorted in ascending order.",
  //       answer_choice: "choice_2",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Junior",
  //       type_of_question: ["Technical Proficiency Assessment"],
  //     },
  //     {
  //       question: "What is a higher-order function in JavaScript?",
  //       choice_1:
  //         "A function that can be passed as an argument to another function.",
  //       choice_2: "A function that is used to create objects.",
  //       choice_3: "A function that returns another function.",
  //       choice_4: "A function that can only be called once.",
  //       answer_choice: "choice_3",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Junior",
  //       type_of_question: ["Technical Proficiency Assessment"],
  //     },
  //     {
  //       question: "What is the purpose of the `const` keyword in JavaScript?",
  //       choice_1: "To define a new object with specific properties and values.",
  //       choice_2: "To create a new instance of an existing class.",
  //       choice_3: "To declare a new variable.",
  //       choice_4: "To define a function that can be used later.",
  //       answer_choice: "choice_3",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Junior",
  //       type_of_question: ["Technical Proficiency Assessment"],
  //     },
  //     {
  //       question: "What does the `concat()` method do in JavaScript?",
  //       choice_1: "To add a new element to the beginning of an array.",
  //       choice_2: "To remove the first element from an array.",
  //       choice_3: "To iterate over each element in an array.",
  //       choice_4: "To combine multiple arrays into one.",
  //       answer_choice: "choice_4",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Mid",
  //       type_of_question: ["Technical Proficiency Assessment"],
  //     },
  //     {
  //       question: "What is object-oriented programming (OOP) in JavaScript?",
  //       choice_1:
  //         "It is a built-in method that allows you to access and modify elements of an object.",
  //       choice_2:
  //         "It is a way to create a new object based on an existing object.",
  //       choice_3:
  //         "It is a design pattern that encourages creating objects with methods that operate on their own data.",
  //       choice_4:
  //         "It is a type of data structure that organizes data into key-value pairs.",
  //       answer_choice: "choice_3",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Mid",
  //       type_of_question: ["Technical Proficiency Assessment"],
  //     },
  //     {
  //       question: "What does the `typeof` operator do in JavaScript?",
  //       choice_1: "To check if a variable is undefined.",
  //       choice_2: "To check if a variable is defined and not null.",
  //       choice_3: "To check if a variable is a string.",
  //       choice_4: "To check if a variable is a number.",
  //       answer_choice: "choice_1",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Mid",
  //       type_of_question: ["Technical Proficiency Assessment"],
  //     },
  //     {
  //       question:
  //         "What is the most efficient way to check if a value exists in an array in JavaScript?",
  //       choice_1:
  //         "Using a for loop to iterate over each element in the array and check its value.",
  //       choice_2:
  //         "Using the `includes()` method to see if the array contains the desired value.",
  //       choice_3:
  //         "Using the `findIndex()` method to find the index of the first occurrence of the value.",
  //       choice_4:
  //         "Using the `some()` method to check if at least one element in the array matches the value.",
  //       answer_choice: "choice_2",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Senior",
  //       type_of_question: ["Problem-Solving Evaluation"],
  //     },
  //     {
  //       question:
  //         "What is the best way to handle potential errors in JavaScript?",
  //       choice_1: "The `try...catch` block.",
  //       choice_2: "The `if...else` statement.",
  //       choice_3: "The `switch` statement.",
  //       choice_4: "The `while` loop.",
  //       answer_choice: "choice_1",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Senior",
  //       type_of_question: ["Problem-Solving Evaluation"],
  //     },
  //     {
  //       question: "What is the main benefit of using functions in JavaScript?",
  //       choice_1: "It reduces code complexity.",
  //       choice_2: "It improves performance.",
  //       choice_3: "It promotes code reusability.",
  //       choice_4: "It makes code more secure.",
  //       answer_choice: "choice_3",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Senior",
  //       type_of_question: ["Experience-Based Questions"],
  //     },
  //     {
  //       question:
  //         "What is a best practice for declaring variables in JavaScript?",
  //       choice_1: "Always use `var` to declare variables.",
  //       choice_2: "Declare all variables at the top of the function.",
  //       choice_3: "Use global variables whenever possible.",
  //       choice_4: "Use `const` and `let` for variable declarations.",
  //       answer_choice: "choice_4",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Senior",
  //       type_of_question: ["Experience-Based Questions"],
  //     },
  //     {
  //       question:
  //         "How do you typically organize your JavaScript code for a large project?",
  //       choice_1: "Using a single, large JavaScript file for all your code.",
  //       choice_2: "Breaking your code into modules and using a module bundler.",
  //       choice_3: "Using a framework like jQuery to manage code organization.",
  //       choice_4: "Copying and pasting code snippets as needed.",
  //       answer_choice: "choice_2",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Senior",
  //       type_of_question: ["Experience-Based Questions"],
  //     },
  //     {
  //       question:
  //         "How do you choose the appropriate tools (libraries and frameworks) for a JavaScript project?",
  //       choice_1: "Using a library like React or Vue.js.",
  //       choice_2: "Using a framework like Angular or Ember.js.",
  //       choice_3:
  //         "Using a combination of libraries and frameworks depending on the project's needs.",
  //       choice_4:
  //         "Writing all code from scratch without using any libraries or frameworks.",
  //       answer_choice: "choice_3",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Senior",
  //       type_of_question: ["Growth and Adaptability"],
  //     },
  //     {
  //       question:
  //         "What is a current trend in JavaScript development that you're interested in?",
  //       choice_1: "Asynchronous programming.",
  //       choice_2: "Server-side rendering.",
  //       choice_3: "Object-oriented programming.",
  //       choice_4: "Functional programming.",
  //       answer_choice: "choice_1",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Senior",
  //       type_of_question: ["Growth and Adaptability"],
  //     },
  //     {
  //       question:
  //         "What is a recent best practice in JavaScript development that you've adopted?",
  //       choice_1: "To use more `for` loops for better performance.",
  //       choice_2: "To minimize the use of global variables.",
  //       choice_3: "To avoid using object-oriented programming.",
  //       choice_4: "To use less comments in code.",
  //       answer_choice: "choice_2",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Senior",
  //       type_of_question: ["Growth and Adaptability"],
  //     },
  //     {
  //       question: "What are your thoughts on the future of JavaScript?",
  //       choice_1: "TypeScript will eventually replace JavaScript.",
  //       choice_2: "JavaScript will become less relevant in the future.",
  //       choice_3: "JavaScript will only be used for web development.",
  //       choice_4:
  //         "JavaScript will continue to evolve with new features and applications.",
  //       answer_choice: "choice_4",
  //       skill: "JavaScript",
  //       criteria: "Programming Languages",
  //       level: "EASY",
  //       experience_level: "Senior",
  //       type_of_question: ["Growth and Adaptability"],
  //     },
  //   ];

  const [questions, setQuestions] = useState([]);

  const [selectedindx, setSelectedindx] = useState(null);

  const [loading, setLoading] = useState(false);
  const [selectLevel, setSelectLevel] = useState("EASY");

  const fetchQUsn = async () => {
    setLoading(true);
    try {
      const response = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_GET_MCQ_QUESTION,
        1,
        {
          jobId: jobId,
          level: selectLevel,
          skill: selectedindx ?? "",
        },
      );
      if (response?.data?.mcqs) {
        setQuestions(response?.data?.mcqs);
        setLoading(false);
      } else {
        setQuestions([]);
        setLoading(false);
      }
    } catch (error) {
      setQuestions([]);
      console.error("Failed to fetch questions", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedindx) {
      fetchQUsn();
    }
  }, [selectedindx, selectLevel]);

  const level = [
    { name: "EASY", value: "EASY" },
    { name: "MEDIUM", value: "MEDIUM" },
    { name: "HARD", value: "HARD" },
  ];

  return (
    <Modal
      heading={"MCQ sample questions"}
      modalDismiss={() => modalDismiss()}
      className={"md:max-w-[60%] "}
      onSave={fetchQUsn}
      isLoading={loading}
      noBtns={true}
    >
      <div className="max-h-[400px] w-full  overflow-auto flex flex-wrap">
        {Array?.isArray(skillGroups) && skillGroups?.length > 0 ? (
          <div className="flex flex-wrap">
            {skillGroups
              ?.flatMap((group) =>
                group?.skills?.filter((skill) => skill.status === true),
              )
              ?.map((skill, idx) => (
                <SkillItem
                  key={`${skill?.label}`}
                  skill={skill?.label}
                  isSelected={skill?.label === selectedindx}
                  onToggle={() => setSelectedindx(skill?.label)}
                />
              ))}
          </div>
        ) : (
          <p className="text-sm text-gray-500">No skill criteria available.</p>
        )}
      </div>

      <div className="mt-2">
        <label className="block text-sm font-medium text-gray-700">
          Select Level
        </label>

        <div className="flex items-center gap-2">
          {level?.map((item, idx) => (
            <span
              key={idx}
              className={`block text-sm text-gray-800 p-2 border-gray-300 border rounded-lg cursor-pointer ${selectLevel === item.value ? "bg-gray-300" : "bg-gray-100"}`}
              onClick={(e) => setSelectLevel(item.value)}
            >
              {item.name}
            </span>
          ))}
        </div>
      </div>
      {loading ? (
        <div className="mt-4 flex justify-center">
          <ScaleLoader height={14} color="#1563E9" loading={true} />
        </div>
      ) : (
        <>
          {questions?.length > 0 ? (
            <>
              {questions?.map((question, idx) => (
                <>
                  {idx <= 2 && (
                    <div className=" p-2 border-b-[1px] border-b-gray-300 rounded-xl">
                      {/* Header Section */}
                      <div className="space-y-4 mb-6">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2">
                            {/* <span className="px-3 py-1 text-sm bg-gray-100  text-gray-800  rounded-full">
                    {question.level}
                  </span> */}
                            <span className="px-3 py-1 text-sm bg-gray-100  text-gray-800 dark:text-gray-100 rounded-full">
                              {question?.experience_level}
                            </span>
                          </div>
                        </div>
                        <h2 className="text-[16px] font-medium text-gray-900 ">
                          {`Q.${idx + 1} ${question?.question}`}
                        </h2>
                      </div>

                      {/* Choices Section */}
                      <div className="space-y-2">
                        {Object?.keys(question)
                          .filter((key) => key?.startsWith("choice_"))
                          .map((choiceKey) => (
                            <div
                              key={choiceKey}
                              className={`p-1 rounded-lg border transition-all ${
                                choiceKey === question?.answer_choice
                                  ? "border-green-500 bg-green-50 "
                                  : "border-gray-200  hover:border-gray-300 "
                              }`}
                            >
                              <div className="flex justify-between items-center">
                                <span className="text-sm text-gray-800 ">
                                  {question[choiceKey]}
                                </span>
                              </div>
                            </div>
                          ))}

                        {/* Navigation Buttons */}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </>
          ) : (
            <div className="flex justify-center mt-4">
              <p className="text-lg font-medium text-gray-700">
                No questions available.
              </p>
            </div>
          )}
        </>
      )}
    </Modal>
  );
}

export default ViewMcqQsn;
