import React, { useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import { useNavigate } from "react-router";
import { ScaleLoader } from "react-spinners";
import { useStatusModal } from "../../../App";
import SelectAndSearch from "../../../layouts/SelectAndSearch";
const core = require("../../../lib/core");

function EmailSequenceConfigModal({ modalDismiss, jobId }) {
  const onCancel = () => {
    modalDismiss();
  };

  const nav = useNavigate();
  const [loading, setLoading] = useState(false);

  const [events, setEvents] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [eventMap, setEventMap] = useState([]);
  const { showSuccess, showError } = useStatusModal();

  // Fetch Sequences and Events
  const getData = async () => {
    try {
      const [sequences, events, eventMaps] = await Promise.all([
        core.API(core.API_METHODS.GET, core.SEQUENCE_API.GET_ALL_SEQUENCES, 1),
        core.API(
          core.API_METHODS.GET,
          core.SEQUENCE_API.GET_SEQUENCE_EVENTS + jobId,
          1,
        ),
        await core.API(
          core.API_METHODS.GET,
          core.SEQUENCE_API.GET_SEQUENCE_EVENTS_MAP + jobId,
          1,
        ),
      ]);
      if (sequences.response) {
        setSequences(sequences.response);
      }
      if (events.response) {
        setEvents(events.response);
      }
      if (eventMaps.response) {
        setEventMap(eventMaps.response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      for (let i = 0; i < eventMap.length; i++) {
        const event = eventMap[i];
        if (!event?.is_modified) continue;
        if (!event.sequence_id && !event._id) continue;
        if (!event.sequence_id) {
          // Delete the event mapping
          const res = await core.API(
            core.API_METHODS.DELETE,
            core.SEQUENCE_API.SEQUENCE_EVENTS_MAP + event._id,
            1,
          );
          if (res.response) {
            eventMap[i].is_modified = false;
          }
        } else if (event?._id) {
          // Update the event mapping
          const sequence = event.sequence_id;
          const eventId = event.event_type_id;
          const res = await core.API(
            core.API_METHODS.PUT,
            core.SEQUENCE_API.SEQUENCE_EVENTS_MAP + event._id,
            1,
            {
              sequence_id: sequence,
              event_type_id: eventId,
              job_id: jobId,
            },
          );
          if (res.response) {
            eventMap[i].is_modified = false;
          }
        } else {
          // Create the event mapping
          const sequence = event.sequence_id;
          const eventId = event.event_type_id;
          const res = await core.API(
            core.API_METHODS.POST,
            core.SEQUENCE_API.POST_SEQUENCE_EVENTS_MAP,
            1,
            {
              sequence_id: sequence,
              event_type_id: eventId,
              job_id: jobId,
            },
          );
          if (res.response) {
            eventMap[i].is_modified = false;
          }
        }
      }
      setLoading(false);
      modalDismiss();
      showSuccess(
        "Email Sequence Updated",
        "Email sequence has been configured successfully for the job",
      );
    } catch (error) {
      console.log(error);
      modalDismiss();
      showError("Email Sequence Update Failed");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const modalElements = {
    heading: "Email Sequence Configuration",
    subheading: "Configure the email sequence for the job",
    modalDismiss: () => onCancel(),
    defaultButtons: {
      primaryLabel: "Save Changes",
      secLabel: "Cancel",
    },
    onSave: () => {
      handleSubmit();
    },
    isLoading: loading,
  };
  return (
    <Modal {...modalElements}>
      <div className="w-full flex flex-col">
        <div className=" flex flex-col gap-5 max-w-[500px] w-full">
          <div className="w-full flex flex-col gap-5">
            {events.map((event, index) => (
              <div key={event._id}>
                <label
                  htmlFor=""
                  className="text-right text-[14px] font-normal not-italic required"
                >
                  {event.event_name}
                </label>
                <select
                  name={event.event_type}
                  className="w-full border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC]"
                  id=""
                  value={
                    eventMap.find((e) => e.event_type_id === event._id)
                      ?.sequence_id
                  }
                  onChange={(e) => {
                    const eventMapArr = [...eventMap];
                    const emap = eventMapArr.find(
                      (e) => e.event_type_id === event._id,
                    );
                    if (emap) {
                      emap.sequence_id = e.target.value;
                      emap.is_modified = true;
                      setEventMap(eventMapArr);
                    } else {
                      eventMapArr.push({
                        event_type_id: event._id,
                        sequence_id: e.target.value,
                        is_modified: true,
                      });
                      setEventMap(eventMapArr);
                    }
                  }}
                >
                  <option value="">None</option>
                  {sequences.map((sequence, index) => (
                    <option value={sequence._id} key={sequence._id}>
                      {sequence.name}
                    </option>
                  ))}
                </select>

                {/* <SelectAndSearch
                  placeholder="None"
                  defalultValue={eventMap.find((e) => e.event_type_id === event._id)
                    ?.sequence_id}
                  data={sequences.map((sequence) => ({
                    name: sequence.name,
                    id: sequence._id,
                  }))}
                  setSelectedTemplate={(selectedSequence) => {
                    const eventMapArr = [...eventMap];
                    const emap = eventMapArr.find(
                      (e) => e.event_type_id === event._id,
                    );
                    if (emap) {
                      emap.sequence_id = selectedSequence;
                      emap.is_modified = true;
                      setEventMap(eventMapArr);
                    } else {
                      eventMapArr.push({
                        event_type_id: event._id,
                        sequence_id: selectedSequence,
                        is_modified: true,
                      });
                      setEventMap(eventMapArr);
                    }
                  }}
                /> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EmailSequenceConfigModal;
