import React from "react";

const SampleSplitter = ({ isDragging, className, ...props }) => {
  return (
    <div className="h-full flex items-center justify-center absolute right-[-8px] top-0">
      <div
        className={`w-1  cursor-col-resize   hover:bg-[#1369E9] transition-colors duration-200 ${isDragging && "bg-slate-400 select-none"} ${className}`}
        {...props}
      />
    </div>
  );
};

export default SampleSplitter;
