import React, { useState, useContext } from "react";
import Modal from "../../../modal/Modal";
import { Check } from "lucide-react";
import { ScaleLoader } from "react-spinners";
import { useStatusModal } from "../../../../App";
const core = require("../../../../lib/core");
const TabItem = ({ tab, isVisible, onToggle }) => {
  return (
    <div className="flex items-center gap-3 p-3 bg-white rounded-lg border border-gray-200 hover:border-gray-300 transition-all duration-200">
      <button
        onClick={() => onToggle(tab)}
        className={`
                    h-5 w-5 rounded border transition-all duration-200 flex items-center justify-center
                    ${
                      isVisible
                        ? "bg-green-700 border-green-600 hover:bg-green-800"
                        : "border-gray-600 hover:border-gray-700"
                    }
                `}
      >
        {isVisible && <Check className="h-3.5 w-3.5 text-white" />}
      </button>
      <span className="text-sm font-medium text-gray-700">{tab.name}</span>
      <span className="text-xs text-gray-600">({tab.count})</span>
    </div>
  );
};

const ConfigureStatusTabsModal = ({
  statusTabs,
  visibleTabs,
  setVisibleTabs,
  modalDismiss,
  isConfigLoaded,
  setIsConfigLoaded,
  handleTabConfigSetup,
}) => {
  const [Tabs, setTabs] = useState(
    isConfigLoaded ? visibleTabs : statusTabs.map((tab) => tab.name),
  );
  const [Loading, setLoading] = useState(false);
  const { user } = useContext(core.UserContext);
  const { showSuccess, showError } = useStatusModal();
  const [message, setMessage] = useState({});
  const [tabVisibility, setTabVisibility] = useState(
    statusTabs.reduce(
      (acc, tab) => ({
        ...acc,
        [tab.name]: isConfigLoaded ? visibleTabs.includes(tab.name) : true,
      }),
      {},
    ),
  );

  const handleToggle = (tab) => {
    setTabVisibility((prev) => ({
      ...prev,
      [tab.name]: !prev[tab.name],
    }));

    setTabs((prev) =>
      prev.includes(tab.name)
        ? prev.filter((name) => name !== tab.name)
        : [...prev, tab.name],
    );
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const ConfigBody = {
        email: user?.email,
        name: user?.name ?? user?.email?.split("@")[0],
        account: user?.account,
        userId: user?.id,
        statusTabConfig: Tabs,
      };
      if (isConfigLoaded) {
        const json = await core.API(
          core.API_METHODS.PUT,
          core.USER_API.USER_CONFIG,
          1,
          ConfigBody,
        );
        if (json && json?.code === 200) {
          setVisibleTabs(Tabs);
          handleTabConfigSetup(Tabs[0]);
          modalDismiss(); // Close the current modal
          showSuccess(
            "Details Saved",
            "Your configuration has been saved successfully.",
          );
        } else {
          modalDismiss(); // Close the current modal
          showError(
            "Configuration Save Error",
            "An error occurred while attempting to save your configuration settings. ",
          );
        }
      } else {
        const res = await core.API(
          core.API_METHODS.POST,
          core.USER_API.USER_CONFIG,
          1,
          ConfigBody,
        );
        if (res && res.code === 200) {
          setVisibleTabs(Tabs);
          setIsConfigLoaded(true);
          handleTabConfigSetup(Tabs[0]);
          modalDismiss(); // Close the current modal
          showSuccess(
            "Details Saved",
            "Your configuration has been saved successfully.",
          );
        } else {
          modalDismiss(); // Close the current modal
          showError(
            "Configuration Save Error",
            "An error occurred while attempting to save your configuration settings. ",
          );
        }
      }
    } catch (error) {
      console.log(error);
      modalDismiss(); // Close the current modal
      showError(
        "Configuration Save Error",
        "An error occurred while attempting to save your configuration settings. ",
      );
    } finally {
      setLoading(false);
    }
  };

  const modalElements = {
    heading: "Configure Status Tabs",
    subheading: "Select which tabs should be visible in the dashboard.",
    modalDismiss: modalDismiss,
    onSave: handleSave,
    defaultButtons: {
      primaryDisabled: Loading || Tabs.length == 0,
      primaryLabel: "Save",
    },
    isLoading: Loading,
  };

  return (
    <Modal {...modalElements} className="sm:!max-w-[40%] !pb-5">
      <div className="mx-auto">
        <div className="space-y-6">
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center justify-between mb-3">
              <h3 className="text-sm font-semibold text-gray-700">
                Status Tabs
              </h3>
              <span className="text-xs text-gray-600">
                {Object.values(tabVisibility).filter(Boolean).length} visible
              </span>
            </div>
            <div className="space-y-2">
              {statusTabs.map((tab) => (
                <TabItem
                  key={tab.name}
                  tab={tab}
                  isVisible={tabVisibility[tab.name]}
                  onToggle={handleToggle}
                />
              ))}
              {statusTabs.length === 0 && (
                <div className="w-full flex items-center justify-center text-sm text-gray-600 py-8">
                  No tabs available
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 transition-all duration-300 ease-in-out">
        {Object.entries(message)?.length > 0 && (
          <div
            className={`${message?.type === "Success" ? "text-green-800" : "text-red-800"} text-base flex items-center gap-2 justify-start `}
          >
            {message?.type === "Success" ? (
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 2048 2048"
                >
                  <path
                    fill="currentColor"
                    d="M1024 0q141 0 272 36t244 104t207 160t161 207t103 245t37 272q0 141-36 272t-104 244t-160 207t-207 161t-245 103t-272 37q-141 0-272-36t-244-104t-207-160t-161-207t-103-245t-37-272q0-141 36-272t104-244t160-207t207-161T752 37t272-37m603 685l-136-136l-659 659l-275-275l-136 136l411 411z"
                  />
                </svg>
              </span>
            ) : (
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.6em"
                  height="1.6em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m-.763-15.864l.11 7.596h1.305l.11-7.596zm.759 10.967c.512 0 .902-.383.902-.882c0-.5-.39-.882-.902-.882a.88.88 0 0 0-.896.882c0 .499.396.882.896.882"
                  />
                </svg>
              </span>
            )}
            <span>{message.message}</span>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ConfigureStatusTabsModal;
