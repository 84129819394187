import React from "react";
import { useSelector } from "react-redux";
var parse = require("html-react-parser");

function getText(html) {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

export const EmailHeader = ({ data }) => {
  const profile = useSelector(
    (state) =>
      state?.jobCandidateMatch?.profile?.matchProfile?.first_name +
      " " +
      state?.jobCandidateMatch?.profile?.matchProfile?.last_name,
  );
  const recruiter = data?.user ? (
    <span
      data-testid="user"
      className="px-2 py-1 rounded-lg bg-blue-200 text-gray-800 text-sm font-semibold"
    >
      {data?.user?.name}
    </span>
  ) : (
    <></>
  );
  const candidate = (
    <span className="px-2 py-1 rounded-lg bg-green-200 text-gray-800 text-sm font-semibold">
      {profile}
    </span>
  );

  return (
    <div className="grow flex justify-start items-center gap-4 ml-2">
      {data?.sent_by_recruiter ? recruiter : candidate}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
        />
      </svg>

      {data?.sent_by_recruiter ? candidate : recruiter}
    </div>
  );
};

function formatTextWithLinks(text) {
  // Regular expression to detect URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Split the text and replace URLs with anchor tags
  const parts = text.split(urlRegex).map((part, index) => {
    if (urlRegex.test(part)) {
      // If the part matches the URL regex, render as an anchor tag
      return (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          {part}
        </a>
      );
    }
    // Otherwise, render as plain text
    return <span key={index}>{part}</span>;
  });

  return parts;
}

export default function EmailContent({ data }) {
  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      {/* Subject */}
      {data?.subject && String(data?.subject)?.length > 0 && (
        <h2 className="text-gray-900 text-lg font-semibold mb-2 border-b border-gray-300 pb-2">
          {data?.subject}
        </h2>
      )}

      {/* Body */}
      {data?.body && String(data?.body)?.length > 0 && (
        <div className="bg-gray-100 text-gray-800 text-sm p-4 rounded-lg mt-4 whitespace-pre-line leading-relaxed prose">
          {/* {formatTextWithLinks(String(data?.body))} */}
          {parse(data?.body?.split("&lt;")?.join("<"))}
        </div>
      )}
    </div>
  );
}
